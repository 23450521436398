import { ZGrid } from "@msidata/zeact";
import * as React from "react";
import styled from "styled-components";

const odata = {
   $select: [
      "EquipmentNumber",
      "Serial",
      "InventoryNumber",
      "Description",
      "Site/OwningCustomer/CustomerNumber",
      "Site/SiteNumber"
   ],
   $expand: ["Site/OwningCustomer", "Site"]
};

export const EquipmentPage = (props: any) => {
   const EquipmentGrid = styled.div`
      margin-top: ${props.isWide ? `60px` : "55px"};
   `;

   return (
      <>
         <div>
            <EquipmentGrid id={"equipment-grid"}>
               <ZGrid
                  endpoint={"Equipment/GetEquipment"}
                  entity={"Equipment"}
                  select={odata.$select}
                  expand={odata.$expand}
                  style={{ width: "100%", height: 500 }}
                  filterable={true}
                  scrollable={"scrollable"}
                  pageable={true}
                  excelExport={true}
                  columns={[
                     {
                        title: "EquipmentNumber",
                        field: "EquipmentNumber",
                        resizable: true
                     },
                     {
                        title: "InventoryNumber",
                        field: "InventoryNumber",
                        resizable: true
                     },
                     {
                        title: "SerialNumber",
                        field: "Serial",
                        resizable: true,
                        cell: ({ dataItem, className }) => {
                           if (dataItem.Serial) {
                              return (
                                 <td className={className}>
                                    {dataItem.Serial}
                                 </td>
                              );
                           } else {
                              return <td className={className}>{"---"}</td>;
                           }
                        }
                     },
                     {
                        title: "Description",
                        field: "Description",
                        resizable: true,
                        cell: ({ dataItem, className }) => {
                           if (dataItem.Description) {
                              return (
                                 <td className={className}>
                                    {dataItem.Description}
                                 </td>
                              );
                           } else {
                              return <td className={className}>{"---"}</td>;
                           }
                        }
                     },
                     {
                        title: "CustomerNumber",
                        field: "Site.OwningCustomer.CustomerNumber",
                        resizable: true
                     },
                     {
                        title: "SiteNumber",
                        field: "Site.SiteNumber",
                        resizable: true
                     }
                  ]}
               />
            </EquipmentGrid>
         </div>
      </>
   );
};

export default EquipmentPage;
