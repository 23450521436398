import { ZFilter, useFieldVisibilityContext } from "@msidata/zeact";
import { useCompanyInfoContext } from "../data/CompanyInfoContext";
import React from "react";
import _ from "lodash";

const defaultFilter = [
   { field: "OrderNumber", operator: "contains" },
   { field: "SiteId", operator: "eq" },
   { field: "CustomerId", operator: "eq" }
];

export const AdvancedFilter = (props: any) => {
   const { fieldVis } = useFieldVisibilityContext();
   const { companyInfo } = useCompanyInfoContext();
   const { userDefinedFields, userDefinedLists } = companyInfo;
   const UserDefinedData = {
      UserDefinedLists: userDefinedLists,
      UserDefinedFields: userDefinedFields
   };
   const fields = React.useMemo(() => {
      const supportedUDFTypes = [
         "STRING",
         "FLOAT",
         "INT",
         "ENUM",
         "DATE",
         "DATETIME",
         "CHECKBOX",
         "TEXTAREA"
      ];
      const getTypeFromUDFType = (udfType: string) => {
         switch (udfType) {
            case "STRING":
            case "TEXTAREA":
               return "text";
            case "FLOAT":
            case "INT":
               return "numeric";
            case "DATE":
               return "date";
            case "DATETIME":
               return "datetime";
            case "CHECKBOX":
               return "boolean";
         }
      };

      let allFields: any[] = [
         { field: "OrderNumber", type: "text" },
         { field: "OrderDate", type: "date" },
         { field: "OrderedBy", type: "text", nullable: true },
         { field: "Description", type: "text", nullable: true },
         { field: "JobNumber", type: "text", nullable: true },
         { field: "CustomerPO", type: "text", nullable: true },
         { field: "OrderTotal", type: "numeric" },
         { field: "SiteAddress1", type: "text", nullable: true },
         { field: "SiteAddress2", type: "text", nullable: true },
         { field: "SiteAddress3", type: "text", nullable: true },
         { field: "SiteCity", type: "text", nullable: true },
         { field: "SiteRegion", type: "text", nullable: true },
         { field: "SitePostalCode", type: "text", nullable: true },
         { field: "SiteCountry", type: "text", nullable: true },
         {
            field: "SiteId",
            type: "entity",
            entity: "Site",
            endpoint: "Site/GetSites",
            textField: "SiteName",
            filterFields: ["Site/SiteNumber", "Site/SiteName"],
            displayFields: ["SiteNumber", "SiteName"]
         },
         {
            field: "CustomerId",
            type: "entity",
            entity: "Customer",
            endpoint: "Customer/GetCustomers",
            textField: "CustomerName",
            filterFields: ["Customer/CustomerNumber", "Customer/CustomerName"],
            displayFields: ["CustomerNumber", "CustomerName"]
         },
         {
            field: "RepairItem/EquipmentId",
            type: "entity",
            entity: "Equipment",
            endpoint: "Equipment/GetEquipment",
            textField: "InventoryNumber",
            filterFields: [
               "Equipment/InventoryNumber",
               "Equipment/EquipmentNumber",
               "Equipment/Serial"
            ],
            displayFields: ["InventoryNumber", "EquipmentNumber", "Serial"]
         }
      ];

      UserDefinedData.UserDefinedFields.filter(
         field => field.TableName === "Order" && field.ShowOnDesktop
      ).forEach(field => {
         if (supportedUDFTypes.includes(field.Type || "")) {
            if (field.Type === "ENUM") {
               allFields.push({
                  field: field.BindName,
                  label: field.DisplayValue,
                  localize: false,
                  type: "list",
                  textField: "Value",
                  listData: UserDefinedData.UserDefinedLists.find(
                     list => list.Id === field.UserDefinedListId
                  )?.Values
               });
            } else {
               allFields.push({
                  field: field.BindName,
                  label: field.DisplayValue,
                  localize: false,
                  type: getTypeFromUDFType(field.Type || "")
               });
            }
         }
      });

      let result: any[] = [];
      let fieldVisibilityMap: any = {};

      _.forEach(fieldVis?.components, (fvs: any) => {
         fieldVisibilityMap[fvs.fieldName] = fvs.visible;
      });

      fieldVisibilityMap["OrderNumber"] = true;

      _.forEach(allFields, (field: any) => {
         let testField = field.field;

         testField =
            testField.indexOf("UDF_") !== -1
               ? testField.substring(4)
               : testField;

         if (
            fieldVisibilityMap[testField] === undefined ||
            fieldVisibilityMap[testField]
         ) {
            result.push(field);
         }
      });

      return result;
   }, [
      UserDefinedData.UserDefinedFields,
      UserDefinedData.UserDefinedLists,
      fieldVis
   ]);

   return (
      <ZFilter
         fields={fields}
         defaultFilter={defaultFilter}
         onChange={(oDataFilter: string) => props.onChange(oDataFilter)}
      />
   );
};
