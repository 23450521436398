import { InspectionResult } from "@msidata/service-pro-api-schema";
import { CacheTimes, OData, ReactQuery } from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";

export interface UseCompletedInspectionResultsParams {
   [key: string]: any;
   orderId: number;
}

export interface UseCompletedInspectionResultsReturnValue {
   data: InspectionResult[] | null;
   error: Error | null;
   done: boolean;
   refresh: () => void;
}

const odata = {
   $expand: ["CompletedByUser", "Inspection", "InspectionResult"],
   $select: [
      "CompletedDateTime",
      "CompletedByUser/EmailAddress",
      "Inspection/InspectionName",
      "Inspection/VersionNumber",
      "InspectionResult/AttachmentId"
   ],
   $top: 1000
};

//@ts-ignore
export const useCompletedInspectionResults = (
   params: UseCompletedInspectionResultsParams & OData
) => {
   const { apiClient } = useAppContext();
   const fetchInspectionResults = (
      key: string,
      params: UseCompletedInspectionResultsParams & OData
   ): Promise<UseCompletedInspectionResultsReturnValue["data"]> =>
      new Promise((res, rej) =>
         apiClient
            .request({
               method: "GET",
               url: "Order/GetInspectionOrderLines",
               params: { ...params, ...odata }
            })
            .then(resp => {
               if (resp.status === 200) {
                  res(resp.data.d.results);
               } else {
                  rej(resp.statusText);
               }
            })
      );

   const { data, error, refetch, isFetching } = ReactQuery.useQuery(
      ["completed-inspection-results", params],
      fetchInspectionResults,
      { suspense: false, staleTime: CacheTimes.FifteenMinutes }
   );

   return {
      data,
      error,
      done: isFetching,
      refresh: refetch
   };
};
