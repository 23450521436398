import * as React from "react";
import { ll, llContext, ZGrid } from "@msidata/zeact";
import styled from "styled-components";

const odata = {
   $select: [
      "CustomerNumber",
      "CustomerName",
      "ShipToDefault/Address1",
      "ShipToDefault/Address2",
      "ShipToDefault/Address3",
      "ShipToDefault/City",
      "ShipToDefault/Region",
      "ShipToDefault/PostalCode"
   ],
   $expand: ["ShipToDefault"]
};

export const Customers = (props: any) => {
   const { languageId } = React.useContext(llContext);
   const { isWide } = props;
   const Wrapper = React.useMemo(
      () => styled.div`
         margin-top: ${isWide ? `60px` : "55px"};
         text-align: left;
      `,
      [isWide]
   );

   return (
      <Wrapper>
         <ZGrid
            title={ll({
               key: "ICanAccess",
               languageId,
               variables: [ll({ key: "Customers", languageId })]
            })}
            endpoint={"Customer/GetCustomers"}
            entity={"Customer"}
            select={odata.$select}
            expand={odata.$expand}
            style={{
               width: "100%",
               height: 500
            }}
            columns={[
               {
                  title: "CustomerNumber",
                  field: "CustomerNumber",
                  columnMenu: null
               },
               {
                  title: "CustomerName",
                  field: "CustomerName",
                  columnMenu: null
               },
               {
                  title: "Address1",
                  field: "ShipToDefault.Address1",
                  columnMenu: null,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.ShipToDefault.Address1) {
                        return (
                           <td className={className}>
                              {dataItem.ShipToDefault.Address1}
                           </td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               },
               {
                  title: "Address2",
                  field: "ShipToDefault.Address2",
                  columnMenu: null,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.ShipToDefault.Address2) {
                        return (
                           <td className={className}>
                              {dataItem.ShipToDefault.Address2}
                           </td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               },
               {
                  title: "City",
                  field: "ShipToDefault.City",
                  columnMenu: null,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.ShipToDefault.City) {
                        return (
                           <td className={className}>
                              {dataItem.ShipToDefault.City}
                           </td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               },
               {
                  title: "Region",
                  field: "ShipToDefault.Region",
                  columnMenu: null,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.ShipToDefault.Region) {
                        return (
                           <td className={className}>
                              {dataItem.ShipToDefault.Region}
                           </td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               },
               {
                  title: "PostalCode",
                  field: "ShipToDefault.PostalCode",
                  columnMenu: null,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.ShipToDefault.PostalCode) {
                        return (
                           <td className={className}>
                              {dataItem.ShipToDefault.PostalCode}
                           </td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               }
            ]}
            scrollable={"scrollable"}
            pageable={true}
            pageSize={10}
         />
      </Wrapper>
   );
};
