import {
   ZDropDownButton,
   ZDropDownButtonItem,
   ZDropDownButtonProps,
   ll,
   llContext,
   localeContext
} from "@msidata/zeact";
import * as React from "react";
import styled from "styled-components";
import {
   defaultCulture,
   useCompanyInfoContext
} from "../data/CompanyInfoContext";
import _ from "lodash";
import { useUserPreferences } from "../hooks/useUserPreferences";

export const LocalePicker = styled(
   (props: Partial<ZDropDownButtonProps> & { className?: string }) => {
      const { updateUserPreferencesMutation } = useUserPreferences();
      const { companyInfo } = useCompanyInfoContext();
      const { codes } = companyInfo;

      const { languageId } = React.useContext(llContext);
      const { localeId, setLocaleId } = React.useContext(localeContext);

      const locales = [
         { value: "en-US", text: "🇺🇸 English (US)" },
         { value: "en-GB", text: "🇬🇧 English (UK)" },
         { value: "en-AU", text: "🇦🇺 English (Australia)" },
         { value: "en-NZ", text: "🇳🇿 English (New Zealand)" },
         { value: "en-ZA", text: "🇿🇦 English (South Africa)" },
         { value: "en-QT", text: "🇶🇦 English (Qatar)" },
         { value: "es-MX", text: "🇲🇽 Spanish (México)" },
         { value: "fr-FR", text: "🇫🇷 French (France)" },
         { value: "ja-JP", text: "🇯🇵 Japanese (Japan)" }
      ];
      const excludedCodeNumbers = [] as string[];

      const localeCodes = codes?.Culture
         ? codes.Culture.filter(
              c => !_.includes(excludedCodeNumbers, c.CodeNumber)
           ).map(culture => ({
              ...culture,
              text:
                 locales.find(l => l.value === culture.Value)?.text ||
                 culture.Value
           }))
         : [{ ...defaultCulture, text: defaultCulture.Value }];
      const [selection, setSelection] = React.useState(
         localeCodes.findIndex(
            l => l.Value.toLowerCase() === localeId.toLowerCase()
         )
      );
      const switchLocale = (index: number) => {
         const value = localeCodes[index].Value;

         updateUserPreferencesMutation &&
            updateUserPreferencesMutation({
               Locale: value
            }).then(() => {
               setSelection(index);
               if (setLocaleId) {
                  setLocaleId(value);
               }
            });
      };
      return (
         <div className={props.className}>
            <ZDropDownButton
               {...props}
               text={`${ll({
                  key: "Locale",
                  languageId
               })}:    ${
                  localeCodes[selection] ? localeCodes[selection].text : "🌐"
               }`}
               onItemClick={(e: any) => switchLocale(e.itemIndex)}
               look={"bare"}
               popupClass={props.className}
               localize={false}
            >
               {localeCodes.map((locale, index) => (
                  <ZDropDownButtonItem
                     text={locale.text}
                     key={`locale-${index}`}
                     selected={selection === index}
                  />
               ))}
            </ZDropDownButton>
         </div>
      );
   }
)`
   float: left;
`;
