import { ZAlertOptions } from "@msidata/zeact";
import * as FileSaver from "file-saver";

export interface DownloadAttachmentArgs {
   apiClient: any;
   issueAlert: (options: ZAlertOptions) => string | number;
   attachmentId?: number;
   endpoint?: string;
}

export const downloadAttachment = (
   args: DownloadAttachmentArgs
): string | number | void => {
   const { attachmentId, apiClient, issueAlert, endpoint } = args;

   if (!endpoint && !attachmentId) {
      const key = issueAlert({
         type: "error",
         header: "UnableToOpenAttachment",
         message: "AttachmentIdIsNullOrUndefined"
      });
      return key;
   }

   apiClient
      .get(endpoint || `Media/GetMedia?attachmentId=${attachmentId}`, {
         responseType: "blob"
      })
      .then((response: any) => {
         FileSaver.saveAs(response.data, response.headers["file-name"]);
      })
      .catch(() =>
         issueAlert({
            type: "error",
            message: "UnableToOpenAttachment"
         })
      );
};

export const getDateTimeString = (): string => {
   const date = new Date();
   let dateStr = `${date.getFullYear()}`;
   dateStr +=
      date.getMonth() + 1 < 10
         ? `0${date.getMonth() + 1}`
         : (date.getMonth() + 1).toString();
   dateStr +=
      date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString();
   dateStr +=
      date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
   dateStr +=
      date.getMinutes() < 10
         ? `0${date.getMinutes()}`
         : date.getMinutes().toString();
   dateStr +=
      date.getSeconds() < 10
         ? `0${date.getSeconds()}`
         : date.getSeconds().toString();
   return dateStr;
};

export const getCookie = (name: string): string => {
   const decodedCookie = decodeURIComponent(document.cookie);
   const cookies = decodedCookie.split(";");
   for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
         cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
         return cookie.substring(name.length + 1, cookie.length);
      }
   }
   return "";
};

export const removeCookie = (name: string) => {
   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
};
