import * as React from "react";
import { ll, llContext, ZGrid } from "@msidata/zeact";
import styled from "styled-components";

const odata = {
   $select: [
      "SiteNumber",
      "SiteName",
      "ShipTo/Address1",
      "ShipTo/Address2",
      "ShipTo/Address3",
      "ShipTo/City",
      "ShipTo/Region",
      "ShipTo/PostalCode"
   ],
   $expand: ["ShipTo"]
};

export const Sites = (props: any) => {
   const { languageId } = React.useContext(llContext);
   const { isWide } = props;
   const Wrapper = React.useMemo(
      () => styled.div`
         margin-top: ${isWide ? `60px` : "55px"};
         text-align: left;
      `,
      [isWide]
   );

   return (
      <Wrapper>
         <ZGrid
            title={ll({
               key: "ICanAccess",
               languageId,
               variables: [ll({ key: "Sites", languageId })]
            })}
            endpoint={"Site/GetSites"}
            entity={"Site"}
            select={odata.$select}
            expand={odata.$expand}
            style={{
               width: "100%",
               height: 500
            }}
            columns={[
               {
                  title: "SiteNumber",
                  field: "SiteNumber",
                  columnMenu: null
               },
               {
                  title: "SiteName",
                  field: "SiteName",
                  columnMenu: null
               },
               {
                  title: "Address1",
                  field: "ShipTo.Address1",
                  columnMenu: null,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.ShipTo.Address1) {
                        return (
                           <td className={className}>
                              {dataItem.ShipTo.Address1}
                           </td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               },
               {
                  title: "Address2",
                  field: "ShipTo.Address2",
                  columnMenu: null,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.ShipTo.Address2) {
                        return (
                           <td className={className}>
                              {dataItem.ShipTo.Address2}
                           </td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               },
               {
                  title: "City",
                  field: "ShipTo.City",
                  columnMenu: null,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.ShipTo.City) {
                        return (
                           <td className={className}>{dataItem.ShipTo.City}</td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               },
               {
                  title: "Region",
                  field: "ShipTo.Region",
                  columnMenu: null,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.ShipTo.Region) {
                        return (
                           <td className={className}>
                              {dataItem.ShipTo.Region}
                           </td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               },
               {
                  title: "Country",
                  field: "ShipTo.Country",
                  columnMenu: null,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.ShipTo.Country) {
                        return (
                           <td className={className}>
                              {dataItem.ShipTo.Country}
                           </td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               }
            ]}
            scrollable={"scrollable"}
            pageable={true}
            pageSize={10}
         />
      </Wrapper>
   );
};
