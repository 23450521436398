import {
   ReactQuery,
   displayAndLogErrorWithMessage,
   useAlerts
} from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";

const queryConfig = {
   suspense: false,
   cacheTime: 0
};

export const useGroups = (userId?: number | null): any => {
   const { apiClient } = useAppContext();
   const { issueAlert } = useAlerts();

   const fetchGroups =
      userId !== null
         ? () =>
              apiClient
                 .request({
                    url: `Administration/Group`,
                    params: {
                       userId,
                       $top: 10000
                    }
                 })
                 .then(response => response.data.d.results)
                 .catch(error => {
                    displayAndLogErrorWithMessage(
                       error,
                       "GroupsCannotGet",
                       issueAlert
                    );
                    return Promise.resolve(null);
                 })
         : () => {
              return Promise.resolve(null);
           };

   const { data, isFetching } = ReactQuery.useQuery(
      ["Groups", { userId }],
      fetchGroups,
      queryConfig
   );

   return {
      groups: data,
      pending: isFetching
   };
};
