import {
   CorrelationGroup,
   FieldStateName,
   FontAwesomeIcon,
   FormattedValue,
   ZContainer,
   ZContainerChildren,
   ZLoadingMask,
   ZPortalLabel,
   ZTooltip,
   buildAddressString,
   getUDFValue,
   llContext,
   localeContext,
   longDateFormat,
   ll,
   shortDateFormat,
   longDateTimeFormat,
   shortDateTimeFormat,
   useAlerts,
   useLayoutContext
} from "@msidata/zeact";
import moment from "moment";
import styled from "styled-components";
import { Order as IOrder } from "@msidata/service-pro-api-schema";
import * as React from "react";
import OrderSummary from "./OrderSummary";
import { InspectionResultReport } from "./InspectionResultReport";
import { isAdmin } from "../hooks/useUser";
import {
   getBooleanOption,
   getOptionValue,
   useCompanyInfoContext
} from "../data/CompanyInfoContext";
import _ from "lodash";
import { downloadAttachment } from "../util/utils";
import { useAppContext } from "../data/AppContext";

const OrderNotesPopup = React.lazy(() => import("./OrderNotesPopup"));
const ServiceItemsPopup = React.lazy(() => import("./ServiceItemsPopup"));
const width50px = { width: "50px" };
const fontSize20px = {
   fontSize: "20px"
};
const visibleAndHidden = ["Visible", "Hidden"] as FieldStateName[];
const blankDiv = () => <div />;
const StatusBar = styled(
   (props: {
      status: string;
      onHold: boolean;
      className?: string;
      style?: any;
      key: string;
   }) => <div className={props.className} />
)`
   background: ${props => {
      if (props.onHold === true) {
         return props.theme["on-hold-color"];
      } else if (props.status !== "COMPLETED") {
         return props.theme["in-progress-color"];
      } else if (props.status === "COMPLETED") {
         return props.theme["completed-color"];
      }
      return "white";
   }};
   grid-column: 1;
   grid-row: 1/9;
`;

export interface OrderCellProps {
   dataItem: IOrder;
   dataIndex: number;
   className?: string;
   isWide: boolean;
}
const OrderCellComponent: React.FC<OrderCellProps> = ({
   dataItem,
   dataIndex,
   className
}) => {
   const { isWide } = useLayoutContext();

   const {
      Appointment,
      Customer,
      CustomerPO,
      Description,
      Id,
      JobNumber,
      OnHold,
      OrderDate,
      OrderedBy,
      OrderNumber,
      OrderStatus,
      RepairItem,
      Site,
      TechOrderStatus,
      TotalPrice,
      WaitingFor,
      WaitingForId,
      OrderAttachment,
      LastIntegratedDateTime
   } = dataItem as IOrder;

   const serviceItemsBadge = RepairItem ? RepairItem.length : 0;
   const address = Site?.ShipTo;

   const firstOpenScheduledAppointment = Appointment
      ? _.chain(Appointment)
           .filter(
              appointment =>
                 !!appointment.ScheduledDateTime &&
                 appointment?.AppointmentStatus?.Closed === false
           )
           .sortBy("ScheduledDateTime")
           .head()
           .value()
      : undefined;
   const ScheduledDateTime = firstOpenScheduledAppointment?.ScheduledDateTime;
   const RequestedDateTime =
      firstOpenScheduledAppointment &&
      firstOpenScheduledAppointment.RequestedDateTime
         ? firstOpenScheduledAppointment.RequestedDateTime
         : _.chain(Appointment)
              .filter(
                 appointment =>
                    !!appointment.RequestedDateTime &&
                    appointment?.AppointmentStatus?.Closed === false
              )
              .sortBy("RequestedDateTime")
              .head()
              .value()?.RequestedDateTime;
   const AssignedTech =
      firstOpenScheduledAppointment?.AssignedTech?.ServiceTechName;
   const { localeId } = React.useContext(localeContext);
   const { languageId } = React.useContext(llContext);
   const { companyInfo } = useCompanyInfoContext();
   const { issueAlert } = useAlerts();
   const { apiClient } = useAppContext();
   const { codes, currency, userDefinedFields, userDefinedLists } = companyInfo;
   const UserDefinedData = {
      UserDefinedLists: userDefinedLists,
      UserDefinedFields: userDefinedFields
   };
   const orderSummaryDocumentTypeId = _.find(
      codes.DocumentType,
      dt => dt.CodeNumber === "ORDER SUMMARY"
   )?.Id;
   const orderHasOrderSummaryReport =
      _.filter(
         OrderAttachment,
         oa => oa.Attachment.DocumentTypeId === orderSummaryDocumentTypeId
      ).length > 0;

   const isVista = getBooleanOption("IsVista", false);
   const containerItems = React.useMemo(
      () =>
         [
            <ZPortalLabel
               header={"OrderNumber"}
               content={
                  LastIntegratedDateTime == null && isVista
                     ? ll({ key: "NewOrder", languageId })
                     : OrderNumber
               }
               propsToLocalize={["header"]}
               id={"OrderNumber"}
               key={"OrderNumber"}
            />,
            <ZPortalLabel
               header={"CustomerNumber"}
               content={Customer?.CustomerNumber}
               propsToLocalize={["header"]}
               id={"Customer/CustomerNumber"}
               key={"CustomerNumber"}
            />,
            <ZPortalLabel
               header={"CustomerName"}
               content={Customer?.CustomerName}
               propsToLocalize={["header"]}
               id={"Customer/CustomerName"}
               key={"CustomerName"}
            />,
            <ZPortalLabel
               header={"SiteName"}
               content={Site?.SiteName}
               propsToLocalize={["header"]}
               id={"Site/SiteName"}
               key={"SiteName"}
            />,
            <ZPortalLabel
               header={"SiteNumber"}
               content={Site?.SiteNumber}
               propsToLocalize={["header"]}
               id={"Site/SiteNumber"}
               key={"SiteNumber"}
            />,
            <ZPortalLabel
               header={"SiteAddress"}
               content={buildAddressString(address)}
               propsToLocalize={["header"]}
               fontSize={14}
               id={"Site/ShipTo/*"}
               key={"SiteAddress"}
            />,
            <ZPortalLabel
               header={"OrderedBy"}
               content={OrderedBy}
               propsToLocalize={["header"]}
               id={"OrderedBy"}
               key={"OrderedBy"}
            />,
            <ZPortalLabel
               header={"OrderDate"}
               content={
                  <FormattedValue
                     value={moment(OrderDate)
                        .add(new Date().getTimezoneOffset(), "minutes")
                        .toDate()}
                     format={isWide ? longDateFormat : shortDateFormat}
                  />
               }
               propsToLocalize={["header"]}
               id={"OrderDate"}
               key={"OrderDate"}
            />,
            <ZPortalLabel
               header={"OrderTotal"}
               content={
                  <FormattedValue
                     value={TotalPrice}
                     currency={currency}
                     format={"c"}
                  />
               }
               propsToLocalize={["header"]}
               fontSize={18}
               id={"OrderTotal"}
               key={"OrderTotal"}
            />,
            <ZPortalLabel
               header={"LatestAppointmentStatus"}
               content={TechOrderStatus}
               propsToLocalize={["header"]}
               id={"TechOrderStatus"}
               key={"LatestAppointmentStatus"}
            />,
            <ZPortalLabel
               header={"JobNumber"}
               content={JobNumber}
               propsToLocalize={["header"]}
               id={"JobNumber"}
               key={"JobNumber"}
            />,
            <ZPortalLabel
               header={"CustomerPO"}
               content={CustomerPO}
               propsToLocalize={["header"]}
               id={"CustomerPO"}
               key={"CustomerPO"}
            />,
            <ZPortalLabel
               header={"OrderStatus"}
               content={OrderStatus?.CodeNumber}
               propsToLocalize={["header"]}
               id={"OrderStatus/CodeNumber"}
               key={"OrderStatus"}
            />,
            <ZPortalLabel
               header={"NextScheduledAppointment"}
               content={
                  ScheduledDateTime ? (
                     <FormattedValue
                        value={ScheduledDateTime}
                        format={
                           isWide ? longDateTimeFormat : shortDateTimeFormat
                        }
                     />
                  ) : (
                     "NotScheduled"
                  )
               }
               propsToLocalize={["header", !ScheduledDateTime ? "content" : ""]}
               id={"Appointment/ScheduledDateTime"}
               key={"NextScheduledAppointment"}
            />,
            <ZPortalLabel
               header={"RequestedDateTime"}
               content={
                  RequestedDateTime ? (
                     <FormattedValue
                        value={RequestedDateTime}
                        format={
                           isWide ? longDateTimeFormat : shortDateTimeFormat
                        }
                     />
                  ) : (
                     "---"
                  )
               }
               propsToLocalize={["header"]}
               id={"Appointment/RequestedDateTime"}
               key={"RequestedDateTime"}
            />,
            <ZPortalLabel
               header={"WaitingFor"}
               content={WaitingFor?.CodeNumber}
               id={"WaitingFor/CodeNumber"}
               key={"WaitingFor"}
            />,
            <ZPortalLabel
               header={"AssignedTech"}
               content={AssignedTech}
               id={"AssignedTech"}
               key={"AssignedTech"}
            />,
            <ZPortalLabel
               header={"Description"}
               content={Description}
               id={"Description"}
               key={"Description"}
            />,
            ...userDefinedFields
               .filter(udf => !!udf.ShowOnDesktop && udf.Type !== "SIGNATURE")
               .map(udf => (
                  <ZPortalLabel
                     localize={false}
                     header={udf.DisplayValue}
                     content={getUDFValue({
                        data: UserDefinedData,
                        localeId,
                        languageId,
                        udf,
                        value: (dataItem as any)[udf.BindName || ""],
                        formatLength: isWide ? "long" : "short"
                     })}
                     id={udf.FieldName}
                     key={udf.FieldName}
                  />
               ))
         ] as ZContainerChildren,
      [
         AssignedTech,
         Customer,
         CustomerPO,
         Description,
         JobNumber,
         OrderNumber,
         OrderDate,
         OrderStatus,
         OrderedBy,
         RequestedDateTime,
         ScheduledDateTime,
         Site,
         TechOrderStatus,
         TotalPrice,
         WaitingFor,
         userDefinedFields,
         address,
         isWide,
         dataItem,
         UserDefinedData,
         languageId,
         localeId,
         currency,
         isVista,
         LastIntegratedDateTime
      ]
   );
   const ButtonBar = styled.div`
      height: 25px;
      font-size: 22px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: ${isWide ? "flex-start" : "space-evenly"};
   `;
   const Button: any = styled.div`
      color: ${props => props.theme["app-link-color"]};
      display: block;
      width: 30px;
      cursor: pointer;
      :hover {
         color: ${props => props.theme["hover-color"]};
      }
      :active {
         color: ${props => props.theme["active-color"]};
      }
   `;
   const [orderSummaryVisible, setOrderSummaryVisible] = React.useState(false);
   const [inspectionsVisible, setInspectionsVisible] = React.useState(false);
   const [orderNotesVisible, setOrderNotesVisible] = React.useState(false);
   const [serviceItemsVisible, setServiceItemsVisible] = React.useState(false);

   const hideOrderSummary = getBooleanOption("PortalHideOrderSummary", false);
   const hideInspections = getBooleanOption("PortalHideInspections", false);
   const hideOrderNotes = getBooleanOption("PortalHideOrderNotes", false);
   const hideServiceItems = getBooleanOption("PortalHideServiceItems", false);
   const orderSummaryFormat = getOptionValue(
      "PortalOrderSummaryFormat",
      "Portal"
   );

   return (
      <td className={className} key={`order-id-${Id}`} id={`order-id-${Id}`}>
         <React.Suspense
            fallback={<ZLoadingMask containerSelector={`#order-id-${Id}`} />}
         >
            <div className={className} key={`order-id-${Id}`}>
               <div
                  className={["order-cell", isWide ? "wide" : null]
                     .join(" ")
                     .trim()}
               >
                  <StatusBar
                     status={OrderStatus?.CodeNumber}
                     onHold={(WaitingForId !== null && !WaitingFor?.PortalInProgress) || OnHold === true}
                     key={`status-bar-id-${Id}`}
                  />
                  <ZContainer
                     key={`order-id-${Id}`}
                     index={dataIndex}
                     id={"PortalOrderCell"}
                     allowedFieldStates={visibleAndHidden}
                     children={containerItems}
                     hiddenFieldComponent={blankDiv}
                     editable={isAdmin()}
                     numberOfColumns={isWide ? 3 : 2}
                     fvsLevelData={
                        codes.PortalFVSLevel.length > 0
                           ? codes.PortalFVSLevel
                           : undefined
                     }
                     fvsLevelId={
                        OrderStatus?.PortalFVSLevelId
                           ? OrderStatus?.PortalFVSLevelId
                           : undefined
                     }
                  />
                  {orderSummaryVisible && !hideOrderSummary && (
                     <CorrelationGroup>
                        <OrderSummary
                           orderId={Id}
                           onClose={() => setOrderSummaryVisible(false)}
                        />
                     </CorrelationGroup>
                  )}
                  {inspectionsVisible && !hideInspections && (
                     <CorrelationGroup>
                        <InspectionResultReport
                           orderId={Id}
                           onClose={() => setInspectionsVisible(false)}
                        />
                     </CorrelationGroup>
                  )}
                  {orderNotesVisible && !hideOrderNotes && (
                     <React.Suspense fallback={<></>}>
                        <CorrelationGroup>
                           <OrderNotesPopup
                              orderId={Id}
                              visible={orderNotesVisible}
                              onClose={() => setOrderNotesVisible(false)}
                           />
                        </CorrelationGroup>
                     </React.Suspense>
                  )}
                  {serviceItemsVisible && !hideServiceItems && (
                     <React.Suspense fallback={<></>}>
                        <CorrelationGroup>
                           <ServiceItemsPopup
                              orderServiceItems={RepairItem ? RepairItem : []}
                              visible={serviceItemsVisible}
                              onClose={() => setServiceItemsVisible(false)}
                           />
                        </CorrelationGroup>
                     </React.Suspense>
                  )}
                  <ButtonBar>
                     {!hideServiceItems && (
                        <Button onClick={() => setServiceItemsVisible(true)}>
                           <ZTooltip
                              title={"RepairItemsCount"}
                              variables={[serviceItemsBadge]}
                           >
                              <FontAwesomeIcon icon={"cube"} />
                           </ZTooltip>
                        </Button>
                     )}
                     {!hideOrderNotes && (
                        <Button onClick={() => setOrderNotesVisible(true)}>
                           <ZTooltip title={"OrderNotes"}>
                              <FontAwesomeIcon icon={"sticky-note"} />
                           </ZTooltip>
                        </Button>
                     )}
                     {!hideInspections && (
                        <Button onClick={() => setInspectionsVisible(true)}>
                           <ZTooltip title={"CompletedInspectionResults"}>
                              <FontAwesomeIcon icon={"clipboard-check"} />
                           </ZTooltip>
                        </Button>
                     )}
                     {!hideOrderSummary && (
                        <Button
                           disabled={
                              orderSummaryFormat === "BackOffice" &&
                              !orderHasOrderSummaryReport
                           }
                           onClick={() => {
                              if (orderSummaryFormat === "BackOffice") {
                                 if (orderHasOrderSummaryReport) {
                                    downloadAttachment({
                                       endpoint: `Order/GetLatestOrderSummaryReport?orderId=${Id}`,
                                       apiClient: apiClient,
                                       issueAlert: issueAlert
                                    });
                                 }
                              } else {
                                 setOrderSummaryVisible(true);
                              }
                           }}
                           style={width50px}
                        >
                           <ZTooltip
                              title={
                                 orderSummaryFormat === "BackOffice"
                                    ? orderHasOrderSummaryReport
                                       ? "OrderSummary"
                                       : "PortalNoOrderSummaryOnOrder"
                                    : "OrderSummary"
                              }
                           >
                              <>
                                 <FontAwesomeIcon icon={"clipboard"} />
                                 <FontAwesomeIcon
                                    style={fontSize20px}
                                    icon={"pencil"}
                                 />
                              </>
                           </ZTooltip>
                        </Button>
                     )}
                  </ButtonBar>
               </div>
            </div>
         </React.Suspense>
      </td>
   );
};

const StyledOrderCell = styled(OrderCellComponent)`
   .portal-label-text {
      display: contents;
   }
   td& {
      margin: 0px;
      padding: 0px;
      line-height: 1.25;
      position: relative;
   }
   & .order-cell {
      width: 100vw;
      &.wide {
         width: 100%;
         max-width: 1080px;
      }
      display: inline-grid;
      & div.portal-label div {
         &:nth-child(2n) {
            justify-content: flex-end;
         }
         justify-content: flex-start;
         align-content: flex-start;
      }
      & div.portal-label {
         &:nth-child(2n) {
            text-align: right;
            justify-content: flex-end;
         }
         justify-content: flex-start;
         align-content: flex-start;
      }
      &.wide div.portal-label,
      &.wide div.portal-label div {
         &:nth-child(2n) {
            text-align: unset;
            justify-content: unset;
         }
         justify-content: flex-start;
         align-content: flex-start;
      }
      & div.portal-label-header,
      & div.portal-label-content {
         white-space: nowrap;
      }
      grid-template-columns: 6px 1fr;

      tr.k-alt & {
         background-color: #f9f9f9;
      }
      & div.right {
         align-self: center;
         justify-self: flex-end;

         & .portal-label-text {
            text-align: right;
         }
      }
      & .dl-item .portal-label-text {
         display: none;
      }
   }
`;
export default StyledOrderCell;
