import React from "react";
import { defaultLocaleId } from "@msidata/zeact";
import { CompanyInfo, useCompanyInfo, PortalSettings } from "../hooks/useCompanyInfo";

export interface CompanyInfoValue {
   pending: boolean;
   companyInfo: CompanyInfo;
   error: any;
   refresh: () => void;
}

export const CompanyInfoContext = React.createContext({} as CompanyInfoValue);

export interface CompanyInfoProviderProps {
   companyId?: number;
   children?: React.ReactChild;
}

const getLSCompanyId = () => {
   const lsComp = localStorage.getItem("Company-Id")
   let compId = -1;
   if (lsComp) {
      compId = parseInt(lsComp);
   }
   return compId;
}

export const CompanyInfoProvider = (props: CompanyInfoProviderProps) => {
   const { companyId } = props;
   const compId = companyId || getLSCompanyId();
   const { pending, companyInfo, error, refresh } = useCompanyInfo(compId);

   return (
      <CompanyInfoContext.Provider
         value={{ pending, companyInfo, error, refresh }}
      >
         {props.children}
      </CompanyInfoContext.Provider>
   )
}

export const withCompanyInfo = (Component: () => JSX.Element): JSX.Element => {
   const compId = getLSCompanyId();

   return (
      <CompanyInfoProvider companyId={compId}>
         <Component />
      </CompanyInfoProvider>
   )
};

export const useCompanyInfoContext = () => React.useContext(CompanyInfoContext);

const useOptionValue = (optionNumber: string, defaultValue: any): any => {
   const { companyInfo } = React.useContext(CompanyInfoContext);
   const options = companyInfo.options;
   if (!options) {
      return defaultValue;
   }
   return options[optionNumber] !== undefined
      ? options[optionNumber]
      : defaultValue;
}
export const getOptionValue = useOptionValue;

const useBooleanOption = (optionNumber: string, defaultValue: boolean): boolean => {
   const value = useOptionValue(optionNumber, defaultValue);
   return value ? value.toLowerCase() === "true" : defaultValue;
}
export const getBooleanOption = useBooleanOption;

export const defaultCulture = {
   Id: 1,
   Value: defaultLocaleId,
   CodeNumber: defaultLocaleId
};

const useSettingValue = (settingNumber: keyof PortalSettings, defaultValue: any): any => {
   const { companyInfo } = React.useContext(CompanyInfoContext);
   const settings = companyInfo?.settings;
   if (!settings) {
      return defaultValue;
   }
   return settings[settingNumber] !== undefined
      ? settings[settingNumber]
      : defaultValue;
}
export const getSettingValue = useSettingValue;

const useBooleanSetting = (settingNumber: keyof PortalSettings, defaultValue: boolean): boolean => {
   const value = useSettingValue(settingNumber, defaultValue);
   return value ? `${value}`.toLowerCase() === "true" : defaultValue;
}
export const getBooleanSetting = useBooleanSetting;
