import { OrderList } from "./OrderList";
import {
   Cache,
   FieldVisibilityProvider,
   ZButton,
   ZLoadingMask,
   ZPopup,
   ZSorter,
   ZTab,
   ZTabStrip,
   ZTabStripContentProps,
   ZTextBox,
   ZTextBoxProps,
   useApplicationName,
   useDebounce,
   useFieldVisibilitySettings,
   useLayoutContext,
   useViewport
} from "@msidata/zeact";
import { useState } from "react";
import styled from "styled-components";
import * as React from "react";
import { useOrderBadgeCounts } from "../hooks/useOrderBadgeCounts";
import {
   CompositeFilterDescriptor,
   SortDescriptor
} from "@progress/kendo-data-query";
import {
   getBooleanOption,
   useCompanyInfoContext
} from "../data/CompanyInfoContext";
import {
   completedFilters,
   inProgressFilters,
   onHoldFilters
} from "../data/TabFilters";
import { AdvancedFilter } from "./AdvancedFilter";

export enum orderTabs {
   NONE = 0,
   IN_PROGRESS = 1,
   COMPLETED = 2,
   ON_HOLD = 3,
   SCHEDULED = 4
}

const RequestServicePopup = React.lazy(
   () => import("./RequestService/RequestServicePopup")
);

const defaultSort: SortDescriptor[] = [{ field: "OrderDate", dir: "desc" }];
const sortableFields = [
   "Customer/CustomerName",
   "Customer/CustomerNumber",
   "CustomerPO",
   "JobNumber",
   "OrderDate",
   "OrderedBy",
   "OrderNumber",
   "Site/SiteName",
   "Site/SiteNumber",
   { field: "TotalPrice", label: "OrderTotal" }
];
const TabContent = (props: ZTabStripContentProps) => {
   const [filterText, setFilterText] = useState("");
   const debouncedFilterText = useDebounce(filterText, 200);
   const [cache, setCache] = useState<Cache>({});
   const [grid, setGrid] = useState<any>(null);
   const setGridInstance = (gridInstance: any) => setGrid(gridInstance);
   const { innerHeight } = useViewport();
   const [popupIsHidden, setPopupIsHidden] = React.useState(true);
   const { isWide } = useLayoutContext();
   const [advancedFilterEnabled, setAdvancedFilterEnabled] =
      React.useState(false);
   const [advancedFilter, setAdvancedFilter] = React.useState("");
   const debouncedAdvancedFilter = useDebounce(advancedFilter, 200);
   const hideRequestService = getBooleanOption(
      "PortalHideRequestService",
      false
   );
   const { companyInfo, pending: companyInfoPending } = useCompanyInfoContext();
   const { id: companyId } = companyInfo;
   const { appNameAbbreviation } = useApplicationName();
   const fvsParams = React.useMemo(() => {
      return {
         app: appNameAbbreviation,
         containerId: "PortalOrderCell"
      };
   }, [appNameAbbreviation]);
   const { fieldVis, refetch } = useFieldVisibilitySettings(fvsParams);
   const [fvSettings, setFvSettings] = React.useState(fieldVis);
   const selectedTab = props.value;
   React.useEffect(() => {
      setFvSettings(fieldVis);
   }, [fieldVis]);

   React.useEffect(() => {
      if (!companyInfoPending) {
         const cachedCompanyIdString =
            localStorage.getItem("Company-Id") || "-1";
         const cachedCompanyId = parseInt(cachedCompanyIdString);
         if (companyId === cachedCompanyId) {
            refetch({ force: true })
               .then(fvs => {
                  setCache({});
                  setFvSettings(fvs);
               })
               .catch(err => console.error(err));
         }
      }
   }, [companyId, companyInfoPending, refetch]);

   const getFilterFromString = React.useCallback(
      (
         filter: string,
         selectedTab?: number
      ): CompositeFilterDescriptor | undefined => {
         let baseFilter: CompositeFilterDescriptor | undefined = undefined;

         if (filter && !advancedFilterEnabled) {
            baseFilter = {
               logic: "or",
               filters: [
                  {
                     field: "OrderNumber",
                     operator: "startswith",
                     value: filter
                  },
                  {
                     field: "Site/SiteName",
                     operator: "startswith",
                     value: filter
                  },
                  {
                     field: "Customer/CustomerName",
                     operator: "startswith",
                     value: filter
                  },
                  {
                     field: "Customer/CustomerNumber",
                     operator: "startswith",
                     value: filter
                  }
               ]
            };
         }

         if (selectedTab) {
            switch (selectedTab) {
               case orderTabs.IN_PROGRESS:
                  return {
                     logic: "and",
                     filters: baseFilter
                        ? [baseFilter, ...inProgressFilters]
                        : inProgressFilters
                  };
               case orderTabs.COMPLETED:
                  return {
                     logic: "and",
                     filters: baseFilter
                        ? [baseFilter, ...completedFilters]
                        : completedFilters
                  };
               case orderTabs.ON_HOLD:
                  return {
                     logic: "and",
                     filters: baseFilter
                        ? [baseFilter, ...onHoldFilters]
                        : onHoldFilters
                  };
               case orderTabs.SCHEDULED: // filtered on back end through custom endpoint
               default:
                  return baseFilter;
            }
         }
         return baseFilter;
      },
      [advancedFilterEnabled]
   );

   const filter = React.useMemo(
      () => getFilterFromString(debouncedFilterText, selectedTab),
      [debouncedFilterText, getFilterFromString, selectedTab]
   );

   const [sort, setSort] = React.useState(defaultSort);

   return (
      <>
         <div>
            <div className={"portal-action-bar"}>
               <span className="grid-span">
                  <ZTextBox
                     icon={"search"}
                     value={advancedFilterEnabled ? "" : filterText}
                     onChange={
                        (event => {
                           setFilterText(`${event?.target.value || ""}`);
                        }) as ZTextBoxProps["onChange"]
                     }
                     disabled={advancedFilterEnabled}
                     placeholder={
                        "SearchByOrderNumberOrSiteNameOrCustomerNameOrCustomerNumber"
                     }
                     width={300}
                  />
                  <ZButton
                     disabled={advancedFilterEnabled}
                     onClick={(e: React.MouseEvent) =>
                        setAdvancedFilterEnabled(true)
                     }
                  >
                     AdvancedSearch
                  </ZButton>
               </span>
               {!hideRequestService && (
                  <span className="grid-span">
                     <div id={"request-service-button"}>
                        <ZButton
                           primary
                           onClick={(e: React.MouseEvent) => {
                              setPopupIsHidden(false);
                           }}
                        >
                           RequestService
                        </ZButton>
                     </div>
                  </span>
               )}
            </div>
            <FieldVisibilityProvider value={fvSettings}>
               {advancedFilterEnabled ? (
                  <div className="advanced-filter">
                     {
                        <span
                           className="advanced-filter-close k-icon k-i-close"
                           onClick={() => setAdvancedFilterEnabled(false)}
                        />
                     }
                     <AdvancedFilter
                        onChange={(oDataFilter: string) =>
                           setAdvancedFilter(oDataFilter)
                        }
                     />
                  </div>
               ) : undefined}
               <div className="sorter">
                  <ZSorter
                     label="SortBy"
                     fields={sortableFields.filter(field =>
                        fvSettings?.components.length
                           ? fvSettings?.components.find(
                                c =>
                                   c.visible &&
                                   ((typeof field === "string" &&
                                      c.fieldName === field) ||
                                      (typeof field === "object" &&
                                         (c.fieldName === field.field ||
                                            c.fieldName === field.label)))
                             )
                           : field
                     )}
                     initialValue={defaultSort}
                     onChange={e => {
                        setSort(e.value);
                        grid.setState({ sort: e.value });
                     }}
                     maxSorts={2}
                  />
               </div>
               <div id="order-list">
                  <OrderList
                     filter={filter}
                     oDataFilter={
                        advancedFilterEnabled
                           ? debouncedAdvancedFilter
                           : undefined
                     }
                     sort={sort}
                     cache={cache}
                     setCache={setCache}
                     setGridInstance={setGridInstance}
                     selectedTab={selectedTab}
                  />
               </div>
            </FieldVisibilityProvider>
         </div>
         {!popupIsHidden && (
            <React.Suspense
               fallback={
                  <ZPopup
                     title={"RequestService"}
                     visible={true}
                     stage={isWide ? "DEFAULT" : "FULLSCREEN"}
                     width={isWide ? 650 : undefined}
                     height={isWide ? 450 : innerHeight}
                  >
                     <div style={{ height: 365 }}></div>
                     <ZLoadingMask containerSelector={`.z-popup .k-window`} />
                  </ZPopup>
               }
            >
               <RequestServicePopup
                  onClose={() => setPopupIsHidden(true)}
                  gridRefresh={() => grid && grid.refresh()}
               />
            </React.Suspense>
         )}
      </>
   );
};

const TabStrip = (props: any) => {
   const { results, refresh, pending: badgesPending } = useOrderBadgeCounts();
   const { All, InProgress, Completed, OnHold, Scheduled } = results;
   const { companyInfo, pending: companyInfoPending } = useCompanyInfoContext();
   const { id: companyId } = companyInfo;

   React.useEffect(() => {
      if (!companyInfoPending) {
         const cachedCompanyIdString =
            localStorage.getItem("Company-Id") || "-1";
         const cachedCompanyId = parseInt(cachedCompanyIdString);
         if (companyId === cachedCompanyId) {
            refresh();
         }
      }
   }, [companyId, companyInfoPending, refresh]);

   return (
      <>
         <div className={props.className}>
            <ZTabStrip content={TabContent} initialValue={0}>
               <ZTab
                  label={"AllOrders"}
                  badgeColor={"all-orders-color"}
                  badgeValue={badgesPending ? 0 : All}
               />
               <ZTab
                  label={"InProgress"}
                  badgeColor={"in-progress-color"}
                  badgeValue={badgesPending ? 0 : InProgress}
               />
               <ZTab
                  label={"Completed"}
                  badgeColor={"completed-color"}
                  badgeValue={badgesPending ? 0 : Completed}
               />
               <ZTab
                  label={"OnHold"}
                  badgeColor={"on-hold-color"}
                  badgeValue={badgesPending ? 0 : OnHold}
               />
               <ZTab
                  label={"Scheduled"}
                  badgeColor={"scheduled-color"}
                  badgeValue={badgesPending ? 0 : Scheduled}
               />
            </ZTabStrip>
         </div>
      </>
   );
};
const StyledTabs = styled(TabStrip)`
   .MuiTabs-root {
      margin-top: ${props => (props.isWide ? `60px` : "55px")};
   }
   .MuiTab-root {
      overflow: visible;
      padding: 15px;
   }
   .k-grid {
      width: 100%;
      min-width: 300px;
      & .k-grid-norecords {
         line-height: 2;
      }
   }
   .k-grid th,
   .k-grid td {
      padding: 0px;
      min-width: 280px;
   }
   .advanced-filter {
      width: 100%;
      text-align: left;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;

      & .k-filter {
         margin-left: 15px;
      }
   }
   .advanced-filter-close {
      display: block;
      float: right;
      font-size: 24px;
      padding: 10px;
   }
   & .portal-action-bar input {
      grid-column: 1;
   }
   & .portal-action-bar {
      display: grid;
      text-align: initial;
      ${props =>
         props.isWide
            ? `grid-template-columns: auto 1fr;`
            : `grid-template-columns: 1fr;
               justify-items: center;`}

      width: 100%;
      align-items: end;
   }

   & .sorter {
      display: flex;
      align-items: flex-start;
   }

   & .grid-span {
      align-self: end;
      margin-bottom: ${props => (props.isWide ? `5px` : null)};
      justify-self: ${props => (props.isWide ? `end` : `center`)};
      & .k-textbox-container {
         padding-top: ${props => (props.isWide ? null : `0`)};
      }
   }

   & #request-service-button {
      align-self: center;
      justify-self: ${props => (props.isWide ? "end" : "center")};
      grid-column: ${props => (props.isWide ? 2 : 1)};
      grid-row: ${props => (props.isWide ? 1 : 2)};
      margin-bottom: 2px;
      & a {
         text-decoration: none;
      }
   }
`;

export default StyledTabs;
