import {
   FormattedValue,
   ZFilter,
   ZFilterField,
   ZGrid,
   ll,
   shortDateTimeFormat,
   stripHTML,
   useAlerts,
   useDebounce,
   useLanguage,
   useLayoutContext
} from "@msidata/zeact";
import * as React from "react";
import styled from "styled-components";
import { useAppContext } from "../data/AppContext";
import { useCompanyInfoContext } from "../data/CompanyInfoContext";
import { downloadAttachment } from "../util/utils";

const odata = {
   select: [
      "Order/OrderNumber",
      "CreatedDateTime",
      "Contents",
      "AttachmentId",
      "NoteType/CodeNumber",
      "Id"
   ],
   expand: ["NoteType", "Order"],
   sort: [
      { field: "Order/OrderNumber", dir: "desc" as "desc" },
      { field: "CreatedDateTime", dir: "desc" as "desc" }
   ]
};

const Link = styled.a`
   color: ${props => props.theme["app-link-color"]} !important;
   :hover {
      color: ${props => props.theme["hover-color"]} !important;
      cursor: pointer;
   }
   :active {
      color: ${props => props.theme["active-color"]} !important;
   }
`;

const defaultFilter = [{ field: "AttachmentId", operator: "isnotnull" }];

const FilterWrapper = styled.div`
   text-align: left;
   border: 1px solid rgba(0, 0, 0, 0.12);
   box-sizing: border-box;
   padding: 10px;
   position: relative;

   .k-filter-container {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
   }
`;

const SearchInfo = styled.span`
   display: inline-block;
   position: absolute;
   top: 5px;
   right: 10px;
   color: rgba(0, 0, 0, 0.5);
`;

const AdvancedFilter = (props: any) => {
   const { companyInfo } = useCompanyInfoContext();
   const { codes } = companyInfo;
   const { NoteType } = codes;
   const fields = React.useMemo(() => {
      const fields: ZFilterField[] = [
         {
            label: "OrderNumber",
            field: "Order/OrderNumber",
            type: "text"
         },
         {
            label: "OrderDate",
            field: "Order/OrderDate",
            type: "date"
         },
         {
            label: "Attachment",
            field: "AttachmentId",
            type: "null"
         },
         {
            label: "Contents",
            field: "Contents",
            type: "text"
         },
         {
            label: "CreatedDateTime",
            field: "CreatedDateTime",
            type: "datetime"
         },
         {
            label: "NoteType",
            field: "NoteTypeId",
            type: "list",
            listData: NoteType,
            textField: "CodeNumber"
         },
         {
            label: "SiteId",
            field: "Order/SiteId",
            type: "entity",
            entity: "Site",
            endpoint: "Site/GetSites",
            textField: "SiteName",
            filterFields: ["Site/SiteNumber", "Site/SiteName"],
            displayFields: ["SiteNumber", "SiteName"]
         },
         {
            label: "CustomerId",
            field: "Order/CustomerId",
            type: "entity",
            entity: "Customer",
            endpoint: "Customer/GetCustomers",
            textField: "CustomerName",
            filterFields: ["Customer/CustomerNumber", "Customer/CustomerName"],
            displayFields: ["CustomerNumber", "CustomerName"]
         },
         {
            label: "RepairItem/EquipmentId",
            field: "Order/RepairItem/EquipmentId",
            type: "entity",
            entity: "Equipment",
            endpoint: "Equipment/GetEquipment",
            textField: "InventoryNumber",
            filterFields: [
               "Equipment/InventoryNumber",
               "Equipment/EquipmentNumber",
               "Equipment/Serial"
            ],
            displayFields: ["InventoryNumber", "EquipmentNumber", "Serial"]
         }
      ];

      return fields;
   }, [NoteType]);

   return (
      <ZFilter
         fields={fields}
         defaultFilter={defaultFilter}
         onChange={(oDataFilter: string) => props.onChange(oDataFilter)}
      />
   );
};

export const OrderNotesPage = () => {
   const userLanguage = useLanguage();
   const { issueAlert } = useAlerts();
   const [advancedFilter, setAdvancedFilter] = React.useState(
      "(AttachmentId ne null)"
   );
   const debouncedAdvancedFilter = useDebounce(advancedFilter, 200);
   const { isWide } = useLayoutContext();
   const { apiClient } = useAppContext();

   const Wrapper = React.useMemo(
      () => styled.div`
         margin-top: ${isWide ? `60px` : "55px"};
      `,
      [isWide]
   );

   return (
      <Wrapper>
         <FilterWrapper>
            <AdvancedFilter
               onChange={(oDataFilter: string) =>
                  setAdvancedFilter(oDataFilter)
               }
            />
            <SearchInfo>
               {ll({ key: "SearchOrderNotes", languageId: userLanguage })}
            </SearchInfo>
         </FilterWrapper>
         <ZGrid
            endpoint={"Order/GetOrderNotes"}
            entity={"OrderNote"}
            select={odata.select}
            expand={odata.expand}
            sort={odata.sort}
            oDataFilter={debouncedAdvancedFilter}
            style={{ width: "100%", height: 500 }}
            filterable={true}
            scrollable={"scrollable"}
            pageable={true}
            columns={[
               {
                  title: "OrderNumber",
                  field: "Order.OrderNumber",
                  resizable: true
               },
               {
                  title: "CreatedDateTime",
                  field: "CreatedDateTime",
                  resizable: true,
                  filter: "date",
                  cell: ({ dataItem, className }) => {
                     return (
                        <td className={className}>
                           <FormattedValue
                              value={dataItem.CreatedDateTime as Date}
                              format={shortDateTimeFormat}
                           ></FormattedValue>
                        </td>
                     );
                  }
               },
               {
                  title: "Contents",
                  field: "Contents",
                  width: 500,
                  resizable: true,
                  cell: ({ dataItem, className }) => {
                     return (
                        <td className={className}>
                           <div
                              dangerouslySetInnerHTML={{
                                 __html: stripHTML({
                                    html: dataItem.Contents,
                                    keepStyling: true
                                 })
                              }}
                           ></div>
                        </td>
                     );
                  }
               },
               {
                  title: "Attachment",
                  field: "AttachmentId",
                  resizable: true,
                  cell: ({ dataItem, className }) => {
                     if (dataItem.AttachmentId) {
                        return (
                           <td className={className}>
                              <Link
                                 onClick={() =>
                                    downloadAttachment({
                                       attachmentId: dataItem.AttachmentId,
                                       apiClient: apiClient,
                                       issueAlert: issueAlert
                                    })
                                 }
                              >
                                 {ll({
                                    key: "DownloadAttachment",
                                    languageId: userLanguage
                                 })}
                              </Link>
                           </td>
                        );
                     } else {
                        return <td className={className}>{"---"}</td>;
                     }
                  }
               },
               {
                  title: "NoteType",
                  field: "NoteType.CodeNumber",
                  resizable: true
               }
            ]}
         />
      </Wrapper>
   );
};

export default OrderNotesPage;
