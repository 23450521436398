import {
   FormattedValue,
   ZFilter,
   ZFilterField,
   ZGrid,
   ll,
   shortDateTimeFormat,
   useAlerts,
   useDebounce,
   useLanguage,
   useLayoutContext
} from "@msidata/zeact";
import * as React from "react";
import styled from "styled-components";
import { useAppContext } from "../data/AppContext";
import { downloadAttachment } from "../util/utils";

const odata = {
   expand: ["CompletedByUser", "Inspection", "InspectionResult"],
   select: [
      "CompletedDateTime",
      "CompletedByUser/EmailAddress",
      "Inspection/InspectionName",
      "Inspection/VersionNumber",
      "InspectionResult/AttachmentId"
   ],
   sort: [{ field: "CompletedDateTime", dir: "desc" as "desc" }]
};

const Link = styled.a`
   color: ${props => props.theme["app-link-color"]} !important;
   :hover {
      color: ${props => props.theme["hover-color"]} !important;
      cursor: pointer;
   }
   :active {
      color: ${props => props.theme["active-color"]} !important;
   }
`;

const defaultFilter = [
   { field: "InspectionResult/AttachmentId", operator: "isnotnull" }
];

const fields: ZFilterField[] = [
   {
      label: "OrderNumber",
      field: "OrderSegment/Order/OrderNumber",
      type: "text"
   },
   {
      label: "OrderDate",
      field: "OrderSegment/Order/OrderDate",
      type: "date"
   },
   {
      label: "InspectionName",
      field: "Inspection/InspectionName",
      type: "text",
      nullable: true
   },
   {
      label: "VersionNumber",
      field: "Inspection/VersionNumber",
      type: "numeric"
   },
   {
      label: "CompletedBy",
      field: "CompletedByUser/EmailAddress",
      type: "text"
   },
   {
      label: "CompletedDate",
      field: "CompletedDateTime",
      type: "datetime",
      nullable: true
   },

   {
      label: "Attachment",
      field: "InspectionResult/AttachmentId",
      type: "null"
   },
   {
      label: "SiteId",
      field: "OrderSegment/Order/SiteId",
      type: "entity",
      entity: "Site",
      endpoint: "Site/GetSites",
      textField: "SiteName",
      filterFields: ["Site/SiteNumber", "Site/SiteName"],
      displayFields: ["SiteNumber", "SiteName"]
   },
   {
      label: "CustomerId",
      field: "OrderSegment/Order/CustomerId",
      type: "entity",
      entity: "Customer",
      endpoint: "Customer/GetCustomers",
      textField: "CustomerName",
      filterFields: ["Customer/CustomerNumber", "Customer/CustomerName"],
      displayFields: ["CustomerNumber", "CustomerName"]
   },
   {
      label: "RepairItem/EquipmentId",
      field: "OrderSegment/Order/RepairItem/EquipmentId",
      type: "entity",
      entity: "Equipment",
      endpoint: "Equipment/GetEquipment",
      textField: "InventoryNumber",
      filterFields: [
         "Equipment/InventoryNumber",
         "Equipment/EquipmentNumber",
         "Equipment/Serial"
      ],
      displayFields: ["InventoryNumber", "EquipmentNumber", "Serial"]
   }
];

const AdvancedFilter = (props: any) => {
   return (
      <ZFilter
         fields={fields}
         defaultFilter={defaultFilter}
         onChange={(oDataFilter: string) => props.onChange(oDataFilter)}
      />
   );
};

const FilterWrapper = styled.div`
   text-align: left;
   border: 1px solid rgba(0, 0, 0, 0.12);
   box-sizing: border-box;
   padding: 10px;
   position: relative;

   .k-filter-container {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
   }
`;

const SearchInfo = styled.span`
   display: inline-block;
   position: absolute;
   top: 5px;
   right: 10px;
   color: rgba(0, 0, 0, 0.5);
`;

export const EquipmentPage = () => {
   const userLanguage = useLanguage();
   const { issueAlert } = useAlerts();
   const [advancedFilter, setAdvancedFilter] = React.useState(
      "(InspectionResult/AttachmentId ne null)"
   );
   const debouncedAdvancedFilter = useDebounce(advancedFilter, 200);
   const { isWide } = useLayoutContext();
   const { apiClient } = useAppContext();
   const Wrapper = React.useMemo(
      () => styled.div`
         margin-top: ${isWide ? `60px` : "55px"};
      `,
      [isWide]
   );

   return (
      <Wrapper>
         <FilterWrapper>
            <AdvancedFilter
               onChange={(oDataFilter: string) =>
                  setAdvancedFilter(oDataFilter)
               }
            />
            <SearchInfo>
               {ll({ key: "SearchInspections", languageId: userLanguage })}
            </SearchInfo>
         </FilterWrapper>
         <ZGrid
            endpoint={"Order/GetInspectionOrderLines"}
            entity={"OrderLine"}
            select={odata.select}
            expand={odata.expand}
            sort={odata.sort}
            oDataFilter={debouncedAdvancedFilter}
            style={{ width: "100%", height: 500 }}
            filterable={true}
            scrollable={"scrollable"}
            pageable={true}
            columns={[
               {
                  title: "InspectionName",
                  field: "Inspection.InspectionName",
                  filter: "text"
               },
               {
                  title: "VersionNumber",
                  field: "Inspection.VersionNumber",
                  filter: "numeric"
               },
               {
                  title: "CompletedBy",
                  field: "CompletedByUser.EmailAddress",
                  filter: "text",
                  cell: ({ dataItem, className }) => (
                     <td className={className}>
                        {dataItem.CompletedByUser === null ||
                        dataItem.CompletedByUser === undefined
                           ? "---"
                           : dataItem.CompletedByUser.EmailAddress}
                     </td>
                  )
               },
               {
                  title: "CompletedDate",
                  field: "CompletedDateTime",
                  filter: "date",
                  cell: ({ dataItem, className }) => (
                     <td className={className}>
                        {dataItem.CompletedDateTime === null ? (
                           "---"
                        ) : (
                           <FormattedValue
                              value={dataItem.CompletedDateTime}
                              format={shortDateTimeFormat}
                           ></FormattedValue>
                        )}
                     </td>
                  )
               },
               {
                  title: "Attachment",
                  sortable: false,
                  reorderable: false,
                  cell: ({ dataItem, className }) => (
                     <td className={className}>
                        {dataItem.InspectionResult === null ||
                        dataItem.InspectionResult === undefined ||
                        dataItem.InspectionResult.AttachmentId === null ||
                        dataItem.InspectionResult.AttachmentId === undefined ? (
                           <span>---</span>
                        ) : (
                           <Link
                              onClick={() =>
                                 downloadAttachment({
                                    attachmentId:
                                       dataItem.InspectionResult
                                          ?.AttachmentId || 0,
                                    apiClient: apiClient,
                                    issueAlert: issueAlert
                                 })
                              }
                           >
                              {ll({
                                 key: "DownloadAttachment",
                                 languageId: userLanguage
                              })}
                           </Link>
                        )}
                     </td>
                  )
               }
            ]}
         />
      </Wrapper>
   );
};

export default EquipmentPage;
