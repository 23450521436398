import {
   CacheTimes,
   ReactQuery,
   ReactQueryCache,
   displayAndLogErrorWithMessage,
   useAlerts
} from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";

const queryConfig = {
   suspense: false,
   staleTime: CacheTimes.OneHour
};

export const usePortalUsers = (): any => {
   const { apiClient } = useAppContext();
   const { issueAlert } = useAlerts();

   const fetchPortalUsers = () =>
      apiClient
         .request({
            url: `Administration/Users`,
            params: { $top: 10000 }
         })
         .then(response => response.data.d.results)
         .catch(error => {
            displayAndLogErrorWithMessage(
               error,
               "PortalUsersCannotGet",
               issueAlert
            );
            return Promise.resolve([]);
         });

   const { data, isFetching } = ReactQuery.useQuery(
      "PortalUsers",
      fetchPortalUsers,
      queryConfig
   );

   return {
      users: data,
      pending: isFetching,
      refetch: () => ReactQueryCache.invalidateQueries("PortalUsers")
   };
};
