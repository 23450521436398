import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";

export const inProgressFilters: CompositeFilterDescriptor[] = [
    {
        logic: "and",
        filters:[
            {
                field: "OrderStatus/CodeNumber",
                operator: "neq",
                value: "COMPLETED"
            },
            {
                field: "OnHold",
                operator: "neq",
                value: true
            },
            {
                logic: "or",
                filters:[
                    {
                        field: "WaitingForId",
                        operator: "isnull"
                    },
                    {
                        field: "WaitingFor/PortalInProgress",
                        operator: "eq",
                        value: true
                    }
                ]
            }
        ]
    }
];

export const completedFilters: FilterDescriptor[] =
    [{
        field: "OrderStatus/CodeNumber",
        operator: "eq",
        value: "COMPLETED"

    }];

export const onHoldFilters: CompositeFilterDescriptor[] = [
    {
        logic: "and",
        filters: [
            {
                field: "OrderStatus/CodeNumber",
                operator: "neq",
                value: "COMPLETED"
            },
            {
                logic: "or",
                filters: [
                    {
                        logic: "and",
                        filters: [
                            {
                                field: "WaitingForId",
                                operator: "isnotnull"
                            },
                            {
                                field: "WaitingFor/PortalInProgress",
                                operator: "neq",
                                value: true
                            }
                        ]
                    },
                    {
                        field: "OnHold",
                        operator: "eq",
                        value: true
                    }
                ]
            }
        ]
    }
];