import {
   ReactQuery,
   displayAndLogErrorWithMessage,
   useAlerts
} from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";

const queryConfig = {
   suspense: false,
   cacheTime: 0
};

export const useGroupCustomers = (groupName: string | null): any => {
   const { apiClient } = useAppContext();
   const { issueAlert } = useAlerts();

   const fetchGroupCustomers = groupName
      ? () =>
           apiClient
              .request({
                 url: `Administration/GroupCustomer`,
                 params: {
                    groupName,
                    $top: 10000,
                    $select: "CustomerNumber,CustomerName"
                 }
              })
              .then(response => response.data.d.results)
              .catch(error => {
                 displayAndLogErrorWithMessage(
                    error,
                    "GroupCustomersCannotGet",
                    issueAlert
                 );
                 return Promise.resolve([]);
              })
      : () => {
           return Promise.resolve(null);
        };

   const { data, isFetching } = ReactQuery.useQuery(
      ["GroupCustomers", { groupName }],
      fetchGroupCustomers,
      queryConfig
   );

   return {
      groupCustomers: data,
      pending: isFetching
   };
};
