import { loadLocaleData } from "@msidata/zeact";

loadLocaleData([
   "en-US",
   "en-GB",
   "en-ZA",
   "en-NZ",
   "en-AU",
   "es-MX",
   "fr-FR",
   "ja-JP",
   "en-QT"
]);
