import { ReactQuery } from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";

const queryConfig = {
   suspense: false,
   cacheTime: 0
};

export const useFindUser = (emailAddress: string) => {
   const { apiClient } = useAppContext();

   const findUser = emailAddress
      ? () =>
           apiClient
              .request({
                 url: `Administration/User`,
                 params: {
                    emailAddress
                 }
              })
              .then(response => response.data.d.results)
              .catch(error => {
                 return Promise.resolve(null);
              })
      : () => Promise.resolve(null);

   const { data, isFetching } = ReactQuery.useQuery(
      ["FindUser", { emailAddress }],
      findUser,
      queryConfig
   );

   return {
      user: data,
      pending: isFetching
   };
};
