import { ReactQuery, defaultLocaleId, KendoIntl } from "@msidata/zeact";
import { UserDefinedField } from "@msidata/service-pro-api-schema";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../data/AppContext";

export interface Code {
   Id: number;
   CodeNumber: string;
   Value: string;
}

export const defaultCulture: Code = {
   Id: 1,
   Value: defaultLocaleId,
   CodeNumber: defaultLocaleId
};

const defaultCodes = {
   Culture: [defaultCulture],
   CallPriority: [],
   CallType: [],
   NoteType: [],
   PortalFVSLevel: []
};

export interface Option {
   Id: number;
   OptionNumber: string;
   Value: string;
}

export interface UserDefinedList {
   Id: number;
   ListName: string;
   Values: [];
}

export interface PortalSettings {
   DefaultLocale: string;
   CultureId?: number;
}

export interface CompanyInfo {
   codes: {
      [Key: string]: {
         Id: number;
         CodeNumber: string;
         Value: string;
      }[];
   };
   id: number;
   logo?: string;
   name: string;
   options: { [key: string]: string };
   settings: PortalSettings;
   currency: string;
   userDefinedFields: Partial<UserDefinedField & { Type: string }>[];
   userDefinedLists: UserDefinedList[];
}

export interface CompanyInfoValue {
   pending: boolean;
   companyInfo: CompanyInfo;
   error: any;
   refresh: () => void;
}

const queryConfig = {
   cacheTime: 0,
   suspense: false
};

export const useCompanyInfo = (companyId?: number): CompanyInfoValue => {
   const { apiClient } = useAppContext();
   const compId = companyId || localStorage.getItem("Company-Id") || -1;
   const cookieUsed = localStorage.getItem("cookieUsed")?.toLowerCase() || "";
   const history = useHistory();
   const fetchInfo = function () {
      return new Promise<CompanyInfo>((resolve, reject) => {
         const companyInfo: CompanyInfo = JSON.parse(
            localStorage.getItem("companyInfo") || "{}"
         );
         if (companyInfo.id && companyInfo.id === compId && cookieUsed !== "true") {
            resolve(companyInfo);
         } else {
            apiClient
               .post("SelectCompany", null, {
                  params: { companyId: compId }
               })
               .then(resp => {
                  if (resp.status === 200) {
                     const results = resp?.data?.d?.results || {};
                     let compInfo = {} as CompanyInfo;
                     compInfo.id = results?.Id;
                     compInfo.logo = results?.Logo
                        ? `data:image/png;base64,${results.Logo}`
                        : undefined;
                     compInfo.name = results?.Name;

                     const rCodes = results?.Codes || [];
                     let codes: {
                        [key: string]: {
                           Id: number;
                           CodeNumber: string;
                           Value: string;
                        }[];
                     } = {};
                     if (rCodes.length > 1) {
                        rCodes.forEach((c: any) => {
                           codes[c.Key] = c.Value;
                        });
                        compInfo.codes = codes;
                     } else {
                        compInfo.codes = defaultCodes;
                     }

                     const rOptions = results?.Options || [];
                     let options: { [key: string]: string } = {};
                     rOptions.forEach((o: any) => {
                        options[o.OptionNumber] = o.Value;
                     });
                     compInfo.options = options;

                     const settings = results?.Settings || {
                        DefaultLocale: defaultLocaleId,
                        CultureId: 1
                     };
                     compInfo.settings = settings;
                     const companyLocale = codes.Culture
                        ? codes.Culture.find(c => c.Id === settings.CultureId)
                             ?.Value || settings.DefaultLocale
                        : settings.DefaultLocale;
                     const companyCurrency = companyLocale
                        ? KendoIntl.localeCurrency(companyLocale)
                        : "";
                     compInfo.currency = companyCurrency;

                     const rUDFs = results?.UserDefinedFields || [];
                     compInfo.userDefinedFields = rUDFs;

                     const rUDLs = results?.UserDefinedLists || [];
                     compInfo.userDefinedLists = rUDLs;

                     localStorage.setItem(
                        "companyInfo",
                        JSON.stringify(compInfo)
                     );
                     localStorage.removeItem("cookieUsed");
                     resolve(compInfo);
                  } else {
                     localStorage.removeItem("companyInfo");
                     resolve({} as CompanyInfo);
                  }
               })
               .catch(err => {
                  const { config, status, statusText } = err;
                  const apiError = err?.data?.Errors?.General[0];
                  const { headers, url } = config;
                  const correlationId = headers["Correlation-Id"];
                  const correlationGroupId = headers["Correlation-Group-Id"];
                  const split = "/api/portal";
                  const requestUrl = `${split}${url.split(split)[1]}`;
                  const errorObj = {
                     requestUrl,
                     error: apiError?.Error,
                     errorCode: apiError?.ErrorCode,
                     correlationId,
                     correlationGroupId,
                     status,
                     statusText
                  };
                  console.error(JSON.stringify(errorObj, undefined, 4));
                  localStorage.clear();
                  history.push(
                     `/Login#error=${status} ${statusText}&error_description=An error occurred while fetching company info`
                  );
               });
         }
      });
   };

   const { data, error, refetch, isFetching } = ReactQuery.useQuery(
      "SelectCompany",
      fetchInfo,
      queryConfig
   );

   return {
      companyInfo: (data as CompanyInfo) || {
         codes: [],
         options: {},
         settings: {},
         userDefinedFields: [],
         userDefinedLists: []
      },
      error: error,
      refresh: refetch,
      pending: isFetching
   };
};
