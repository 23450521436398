import {
   ErrorBoundary,
   ZGridList,
   ZLoadingLogo,
   useViewport,
   Cache,
   CacheTimes,
   useLayoutContext
} from "@msidata/zeact";
import * as React from "react";
import OrderCell from "./OrderCell";
import {
   CompositeFilterDescriptor,
   SortDescriptor
} from "@progress/kendo-data-query";
import { useCompanyInfoContext } from "../data/CompanyInfoContext";
import _ from "lodash";
import { orderTabs } from "./Tabs";

const defaultOData = {
   $select: [
      "Appointment/AppointmentStatus/Closed",
      "Appointment/AssignedTech/ServiceTechName",
      "Appointment/RequestedDateTime",
      "Appointment/ScheduledDateTime",
      "Customer/CustomerName",
      "Customer/CustomerNumber",
      "CustomerPO",
      "Description",
      "JobNumber",
      "OnHold",
      "OrderDate",
      "OrderedBy",
      "OrderNumber",
      "OrderStatus/CodeNumber",
      "OrderStatus/PortalFVSLevelId",
      "RepairItem/InventoryNumber",
      "RepairItem/Description",
      "RepairItem/SerialNumber",
      "Site/ShipTo/Address1",
      "Site/ShipTo/Address2",
      "Site/ShipTo/Address3",
      "Site/ShipTo/City",
      "Site/ShipTo/PostalCode",
      "Site/ShipTo/Region",
      "Site/SiteName",
      "Site/SiteNumber",
      "TechOrderStatus",
      "TotalPrice",
      "WaitingFor/CodeNumber",
      "WaitingFor/PortalInProgress",
      "OrderAttachment",
      "OrderAttachment/Attachment/DocumentTypeId",
      "LastIntegratedDateTime"
   ],
   $expand: [
      "Appointment",
      "Appointment/AppointmentStatus",
      "Appointment/AssignedTech",
      "Customer",
      "OrderStatus",
      "RepairItem",
      "Site",
      "Site/ShipTo",
      "WaitingFor",
      "OrderAttachment/Attachment"
   ]
};
const style = { width: "100vw" };
export const OrderList = (props: {
   filter?: CompositeFilterDescriptor;
   oDataFilter?: string;
   sort?: SortDescriptor[];
   cache: Cache;
   setCache: React.Dispatch<React.SetStateAction<Cache>>;
   setGridInstance?: (gridInstance: any) => void;
   selectedTab: orderTabs;
}) => {
   const { innerHeight } = useViewport();
   const { isWide } = useLayoutContext();
   const { filter, cache, setCache, selectedTab, sort } = props;
   const { companyInfo, pending } = useCompanyInfoContext();
   const { userDefinedFields } = companyInfo;
   const getParams = React.useCallback(() => {
      let params = Object.assign({}, defaultOData);
      userDefinedFields.forEach(udf =>
         udf?.BindName ? params.$select.push(udf.BindName) : undefined
      );
      return params;
   }, [userDefinedFields]);
   const [odata, setOData] = React.useState(getParams());
   React.useEffect(() => {
      if (!pending) {
         let params = getParams();
         setOData(params);
         setCache({});
      }
   }, [pending, setCache, setOData, getParams]);

   const uniqueExpands = React.useMemo(
      () => _.uniq(odata.$expand),
      [odata.$expand]
   );
   const uniqueSelects = React.useMemo(
      () => _.uniq(odata.$select),
      [odata.$select]
   );

   return (
      <ErrorBoundary>
         <React.Suspense fallback={<ZLoadingLogo />}>
            {pending ? (
               <ZGridList
                  cell={OrderCell}
                  height={innerHeight - (isWide ? 200 : 225)}
                  style={style}
                  pageable={true}
                  pending={true}
               />
            ) : (
               <ZGridList
                  cell={OrderCell}
                  height={innerHeight - (isWide ? 200 : 225)}
                  style={style}
                  pageable={{ info: true, type: "numeric" }}
                  pageSize={10}
                  scrollable={"scrollable"}
                  endpoint={
                     selectedTab === orderTabs.SCHEDULED
                        ? "Order/GetOrdersWithScheduledAppointments"
                        : "Order/GetOrders"
                  }
                  entity={"Order"}
                  expand={uniqueExpands}
                  select={uniqueSelects}
                  filter={filter}
                  oDataFilter={props.oDataFilter}
                  sort={sort}
                  cache={cache}
                  setCache={setCache}
                  cacheDuration={CacheTimes.FifteenMinutes}
                  setGridInstance={props.setGridInstance}
               />
            )}
         </React.Suspense>
      </ErrorBoundary>
   );
};
