import {
   CacheTimes,
   ReactQuery,
   displayAndLogErrorWithMessage,
   useAlerts
} from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";

const queryConfig = {
   suspense: false,
   staleTime: CacheTimes.OneHour
};

export const useAdminSites = (): any => {
   const { apiClient } = useAppContext();
   const { issueAlert } = useAlerts();

   const fetchAdminSites = () =>
      apiClient
         .request({
            url: `Site/GetSites`,
            params: {
               $select: "SiteNumber,SiteName",
               $top: 10000
            }
         })
         .then(response => response.data.d.results)
         .catch(error => {
            displayAndLogErrorWithMessage(error, "SitesCannotGet", issueAlert);
            return Promise.resolve([]);
         });

   const { data, isFetching } = ReactQuery.useQuery(
      "AdminSites",
      fetchAdminSites,
      queryConfig
   );

   return {
      adminSites: data,
      pending: isFetching
   };
};
