import {
   CacheTimes,
   ReactQuery,
   displayAndLogErrorWithMessage,
   useAlerts
} from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";

const queryConfig = {
   suspense: false,
   staleTime: CacheTimes.OneHour
};

export const useAdminCustomers = (): any => {
   const { apiClient } = useAppContext();
   const { issueAlert } = useAlerts();

   const fetchAdminCustomers = () =>
      apiClient
         .request({
            url: "Customer/GetCustomers",
            params: {
               $select: "CustomerNumber,CustomerName",
               $top: 10000
            }
         })
         .then(response => response.data.d.results)
         .catch(error => {
            displayAndLogErrorWithMessage(
               error,
               "CustomersCannotGet",
               issueAlert
            );
            return Promise.resolve([]);
         });

   const { data, isFetching } = ReactQuery.useQuery(
      "AdminCustomers",
      fetchAdminCustomers,
      queryConfig
   );

   return {
      adminCustomers: data,
      pending: isFetching
   };
};
