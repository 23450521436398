import * as React from "react";
import * as ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import App from "./App";
import {
   ZLoadingLogo,
   useApiClientWithGroupCorrelationId,
   CorrelationGroup
} from "@msidata/zeact";
import * as serviceWorker from "./serviceWorker";
import {
   portal,
   GlobalStyles,
   AppContext,
   AppContextValue,
   AlertProvider,
   LLProvider,
   LocaleProvider,
   ReactQuery,
   ErrorBoundary,
   LayoutProvider,
   ll,
   ReactQueryDevtools,
   DynamicSSO
} from "@msidata/zeact";
import "./language/loadLanguageData";
import "./locale/loadLocaleData";
import { useConfiguration } from "./hooks/useConfiguration";
import { useServiceProAPI } from "./api/ServiceProAPI";
import useMedia from "use-media";
import { hijackEffects } from "stop-runaway-react-effects";
import "@progress/kendo-theme-material/dist/all.css";

if (process.env.NODE_ENV !== "production") {
   hijackEffects();
   const whyDidYouRender = require("@welldone-software/why-did-you-render");
   whyDidYouRender(React, { trackAllPureComponents: false });
}

const defaultQueryConfig = {
   queries: { retry: 1, suspense: true, refetchOnWindowFocus: false }
};

const WrappedApp = React.memo((_props: any) => {
   const config = useConfiguration();
   localStorage.setItem("config", JSON.stringify(config));

   const disableSSORegex = new RegExp(config.auth.disableSSORegex || "");
   const nonSSOConnections = config.auth.nonSSOConnections || [];

   const client = useServiceProAPI(config);
   const apiClient = useApiClientWithGroupCorrelationId(client);

   const isMobile = useMedia({ maxWidth: 650 });

   const [languageId, setLanguageId] = React.useState(
      config.defaultLanguage || "en-US"
   );

   const lang = React.useMemo<string>(
      () => languageId || config.defaultLanguage || "en-US",
      [languageId, config.defaultLanguage]
   );

   const [localeId, setLocaleId] = React.useState(
      config.defaultLocale || "en-US"
   );

   const loc = React.useMemo<string>(
      () => localeId || config.defaultLocale || "en-US",
      [localeId, config.defaultLocale]
   );

   const titleKey =
      config.brand?.name.toLowerCase() === "pulseportal"
         ? "PULSEPortal"
         : "ServiceProPortal";

   const appContextValue = React.useMemo(() => {
      return {
         config,
         apiClient,
         isMobile
      };
   }, [config, apiClient, isMobile]);

   return config.loaded ? (
      <ErrorBoundary>
         <AppContext.Provider value={appContextValue as AppContextValue}>
            <LocaleProvider value={{ localeId: loc, setLocaleId }}>
               <LLProvider value={{ languageId: lang, setLanguageId }}>
                  <DynamicSSO
                     disableSSORegex={disableSSORegex}
                     nonSSOConnections={nonSSOConnections}
                     authConfig={config.auth}
                     theme={portal}
                  >
                     <LayoutProvider>
                        <ReactQuery.ReactQueryConfigProvider
                           config={defaultQueryConfig}
                        >
                           <title>
                              {ll({
                                 key: titleKey,
                                 languageId: lang
                              })}
                           </title>
                           <App />
                        </ReactQuery.ReactQueryConfigProvider>
                     </LayoutProvider>
                  </DynamicSSO>
               </LLProvider>
            </LocaleProvider>
         </AppContext.Provider>
      </ErrorBoundary>
   ) : null;
});

ReactDOM.render(
   <CorrelationGroup>
      <GlobalStyles theme={portal} />
      <ThemeProvider theme={portal}>
         <AlertProvider maxAlerts={5}>
            <React.Suspense fallback={<ZLoadingLogo />}>
               <ReactQueryDevtools initialIsOpen={false} />
               <WrappedApp />
            </React.Suspense>
         </AlertProvider>
      </ThemeProvider>
   </CorrelationGroup>,
   document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
