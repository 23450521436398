import * as React from "react";
import {
   CorrelationGroup,
   FontAwesomeIcon,
   FormContextValue,
   ZButton,
   ZCheckbox,
   ZComboBox,
   ZContainer,
   ZForm,
   ZGrid,
   ZListBox,
   ZListBoxChangeEvent,
   ZLoadingMask,
   ZPopup,
   ZTextBox,
   displayAndLogErrorWithMessage,
   ll,
   llContext,
   useAlerts,
   useDebounce
} from "@msidata/zeact";
import styled from "styled-components";
import _ from "lodash";
import { isAdmin } from "../hooks/useUser";
import { usePortalUsers } from "../hooks/usePortalUsers";
import { useGroups } from "../hooks/useGroups";
import { useGroupCustomers } from "../hooks/useGroupCustomers";
import { useGroupSites } from "../hooks/useGroupSites";
import { useUserCustomers } from "../hooks/useUserCustomers";
import { useUserSites } from "../hooks/useUserSites";
import { useAppContext } from "../data/AppContext";
import { useAdminSites } from "../hooks/useAdminSites";
import { useAdminCustomers } from "../hooks/useAdminCustomers";
import { useIsUserGroupAdmin } from "../hooks/useIsUserGroupAdmin";
import { useFindUser } from "../hooks/useFindUser";
import { useConfiguration, PortalConfig } from "../hooks/useConfiguration";

interface EditAccessPopupProps {
   selectedUser: any;
   setSelectedUser: React.Dispatch<any>;
   groups: string[];
}

interface ConfirmResetPasswordProps {
   resetPasswordUser: any;
   setResetPasswordUser: React.Dispatch<any>;
   config: PortalConfig;
}

interface RemoveFromGroupPopupProps {
   selectedRemoveUser: any;
   setSelectedRemoveUser: React.Dispatch<any>;
   groups: string[];
   refetchUsers: (shouldRefetch: boolean) => void;
   userIsAdmin: boolean;
}

const AdminEditAccessPopup = (props: EditAccessPopupProps) => {
   const { selectedUser, setSelectedUser, groups } = props;
   const { adminSites, pending: adminSitesPending } = useAdminSites();
   const {
      adminCustomers,
      pending: adminCustomersPending
   } = useAdminCustomers();
   const [selectedGroup, setSelectedGroup] = React.useState<string | null>(
      null
   );
   const { groupSites, pending: groupSitesPending } = useGroupSites(
      selectedGroup
   );
   const { groupCustomers, pending: groupCustomersPending } = useGroupCustomers(
      selectedGroup
   );
   const {
      isUserGroupAdmin,
      pending: isUserGroupAdminPending
   } = useIsUserGroupAdmin(selectedUser && selectedUser.Id, selectedGroup);
   const { userSites, pending: userSitesPending } = useUserSites(
      selectedUser && selectedUser.Id
   );
   const { userCustomers, pending: userCustomersPending } = useUserCustomers(
      selectedUser && selectedUser.Id
   );
   const { languageId } = React.useContext(llContext);
   const { apiClient } = useAppContext();
   const [isChangingAccess, setIsChangingAccess] = React.useState<boolean>(
      false
   );
   const [selected, setSelected] = React.useState<(string | number)[] | null>(
      null
   );
   const ListBoxWrapper = styled.div`
      position: relative;
   `;
   const PopupContainer = styled.div`
      text-align: left;
   `;
   const { issueAlert } = useAlerts();
   const GroupAdminLabel = styled.div`
      width: 100%;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 10px;
   `;

   return (
      <ZPopup
         title={`${selectedUser.FirstName} ${selectedUser.LastName} - ${selectedUser.EmailAddress}`}
         localize={false}
         visible={true}
         width={800}
         onClose={() => {
            setSelectedUser(null);
            setSelectedGroup(null);
         }}
      >
         <PopupContainer>
            <ZComboBox
               data={groups}
               value={selectedGroup}
               label={"Group"}
               onChange={e => {
                  setSelectedGroup(e.value);
               }}
            />
            <ListBoxWrapper id="ListBoxWrapper">
               {(selectedGroup
                  ? groupSites && groupCustomers
                  : adminSites && adminCustomers) &&
                  userSites &&
                  userCustomers &&
                  !isUserGroupAdminPending ? (
                     <>
                        {isUserGroupAdmin ? (
                           <GroupAdminLabel>
                              {`${selectedUser.FirstName} ${selectedUser.LastName} is an Admin of Group ${selectedGroup}`}
                           </GroupAdminLabel>
                        ) : (
                              <></> 
                           )}
                        <ZListBox
                           height={200}
                           disabled={isUserGroupAdmin || false}
                           lang={{
                              availableHeader: ll({
                                 key: "Available",
                                 languageId
                              }),
                              selectedHeader: ll({
                                 key: "Assigned",
                                 languageId
                              }),
                              moveAllLeft: ll({
                                 key: "UnassignAll",
                                 languageId
                              }),
                              moveAllRight: ll({
                                 key: "AssignAll",
                                 languageId
                              }),
                              moveLeft: ll({ key: "Unassign", languageId }),
                              moveRight: ll({ key: "Assign", languageId })
                           }}
                           options={[
                              {
                                 label: ll({ key: "Sites", languageId }),
                                 options: (selectedGroup
                                    ? groupSites
                                    : adminSites
                                 ).map((userSite: any) => ({
                                    label: `${userSite.SiteNumber} - ${userSite.SiteName}`,
                                    value: `site-${userSite.Id}`
                                 }))
                              },
                              {
                                 label: ll({ key: "Customers", languageId }),
                                 options: (selectedGroup
                                    ? groupCustomers
                                    : adminCustomers
                                 ).map((userCustomer: any) => ({
                                    label: `${userCustomer.CustomerNumber} - ${userCustomer.CustomerName}`,
                                    value: `customer-${userCustomer.Id}`
                                 }))
                              }
                           ]}
                           value={
                              isUserGroupAdmin
                                 ? [
                                    ...groupSites.map(
                                       (site: any) => `site-${site.Id}`
                                    ),
                                    ...groupCustomers.map(
                                       (customer: any) =>
                                          `customer-${customer.Id}`
                                    )
                                 ]
                                 : selected || [
                                    ...userSites.map(
                                       (site: any) => `site-${site.Id}`
                                    ),
                                    ...userCustomers.map(
                                       (customer: any) =>
                                          `customer-${customer.Id}`
                                    )
                                 ]
                           }
                           onChange={(event: ZListBoxChangeEvent) => {
                              setIsChangingAccess(true);
                              const { selected, deselected } = event;
                              let promises: any[] = [];
                              (selected as string[]).forEach((option: string) => {
                                 if (option.startsWith("site")) {
                                    promises.push(
                                       apiClient.post(`Administration/UserSite`, {
                                          UserId: selectedUser.Id,
                                          SiteId: option.split("-")[1]
                                       })
                                    );
                                 } else {
                                    promises.push(
                                       apiClient.post(
                                          `Administration/UserCustomer`,
                                          {
                                             UserId: selectedUser.Id,
                                             CustomerId: option.split("-")[1]
                                          }
                                       )
                                    );
                                 }
                              });
                              (deselected as string[]).forEach(
                                 (option: string) => {
                                    if (option.startsWith("site")) {
                                       promises.push(
                                          apiClient.delete(
                                             `Administration/UserSite`,
                                             {
                                                params: {
                                                   userId: selectedUser.Id,
                                                   siteId: option.split("-")[1]
                                                }
                                             }
                                          )
                                       );
                                    } else {
                                       promises.push(
                                          apiClient.delete(
                                             `Administration/UserCustomer`,
                                             {
                                                params: {
                                                   userId: selectedUser.Id,
                                                   customerId: option.split("-")[1]
                                                }
                                             }
                                          )
                                       );
                                    }
                                 }
                              );
                              Promise.all(promises)
                                 .then(() => setSelected(event.newState))
                                 .catch(error => {
                                    setSelectedUser(null);
                                    setSelectedGroup(null);
                                    displayAndLogErrorWithMessage(
                                       error,
                                       "EditAccessErrorMessage",
                                       issueAlert
                                    );
                                 })
                                 .finally(() => {
                                    setIsChangingAccess(false);
                                 });
                           }}
                        />
                     </>
                  ) : (selectedGroup
                     ? groupSitesPending || groupCustomersPending
                     : adminSitesPending || adminCustomersPending) ||
                     userSitesPending ||
                     userCustomersPending ||
                     isUserGroupAdminPending ? (
                        <ZLoadingMask containerSelector={".k-window"} />
                     ) : (
                        <></>
                     )}
               {isChangingAccess && (
                  <ZLoadingMask containerSelector={"#ListBoxWrapper"} />
               )}
            </ListBoxWrapper>
         </PopupContainer>
      </ZPopup>
   );
};

const GroupAdminEditAccessPopup = (props: EditAccessPopupProps) => {
   const { selectedUser, setSelectedUser, groups } = props;
   const [selectedGroup, setSelectedGroup] = React.useState<string | null>(
      null
   );
   const { groupSites, pending: groupSitesPending } = useGroupSites(
      selectedGroup
   );
   const { groupCustomers, pending: groupCustomersPending } = useGroupCustomers(
      selectedGroup
   );
   const {
      isUserGroupAdmin,
      pending: isUserGroupAdminPending
   } = useIsUserGroupAdmin(selectedUser && selectedUser.Id, selectedGroup);
   const { userSites, pending: userSitesPending } = useUserSites(
      isUserGroupAdmin === false ? selectedUser && selectedUser.Id : null,
      isUserGroupAdmin === false ? selectedGroup : null
   );
   const { userCustomers, pending: userCustomersPending } = useUserCustomers(
      isUserGroupAdmin === false ? selectedUser && selectedUser.Id : null,
      isUserGroupAdmin === false ? selectedGroup : null
   );
   const { languageId } = React.useContext(llContext);
   const { apiClient } = useAppContext();
   const [isChangingAccess, setIsChangingAccess] = React.useState<boolean>(
      false
   );
   const [selected, setSelected] = React.useState<(string | number)[] | null>(
      null
   );
   const ListBoxWrapper = styled.div`
      position: relative;
   `;
   const PopupContainer = styled.div`
      text-align: left;
   `;
   const { issueAlert } = useAlerts();
   const GroupAdminLabel = styled.div`
      width: 100%;
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 10px;
   `;

   React.useEffect(() => {
      if (groups && groups.length === 1 && !selectedGroup) {
         setSelectedGroup(groups[0]);
      }
   }, [groups, selectedGroup]);

   return (
      <ZPopup
         title={`${selectedUser.FirstName} ${selectedUser.LastName} - ${selectedUser.EmailAddress}`}
         localize={false}
         visible={true}
         width={800}
         onClose={() => {
            setSelectedUser(null);
            setSelectedGroup(null);
         }}
      >
         <PopupContainer>
            <ZComboBox
               data={groups}
               value={selectedGroup}
               label={"Group"}
               onChange={e => {
                  setSelectedGroup(e.value);
               }}
            />
            <ListBoxWrapper id="ListBoxWrapper">
               {groupSites &&
                  groupCustomers &&
                  (isUserGroupAdmin === false
                     ? userSites && userCustomers
                     : isUserGroupAdmin === true) ? (
                     <>
                        {isUserGroupAdmin ? (
                           <GroupAdminLabel>
                              {ll({
                                 key: "UserIsAdminOfGroup",
                                 languageId
                              }).format(
                                 `${selectedUser.FirstName} ${selectedUser.LastName}`,
                                 selectedGroup
                              )}
                           </GroupAdminLabel>
                        ) : (
                           <></>
                           )}
                        <ZListBox
                           height={200}
                           disabled={isUserGroupAdmin}
                           lang={{
                              availableHeader: ll({
                                 key: "Available",
                                 languageId
                              }),
                              selectedHeader: ll({
                                 key: "Assigned",
                                 languageId
                              }),
                              moveAllLeft: ll({
                                 key: "UnassignAll",
                                 languageId
                              }),
                              moveAllRight: ll({
                                 key: "AssignAll",
                                 languageId
                              }),
                              moveLeft: ll({ key: "Unassign", languageId }),
                              moveRight: ll({ key: "Assign", languageId })
                           }}
                           options={[
                              {
                                 label: ll({ key: "Sites", languageId }),
                                 options: groupSites.map((userSite: any) => ({
                                    label: `${userSite.SiteNumber} - ${userSite.SiteName}`,
                                    value: `site-${userSite.Id}`
                                 }))
                              },
                              {
                                 label: ll({ key: "Customers", languageId }),
                                 options: groupCustomers.map(
                                    (userCustomer: any) => ({
                                       label: `${userCustomer.CustomerNumber} - ${userCustomer.CustomerName}`,
                                       value: `customer-${userCustomer.Id}`
                                    })
                                 )
                              }
                           ]}
                           value={
                              isUserGroupAdmin
                                 ? [
                                    ...groupSites.map(
                                       (site: any) => `site-${site.Id}`
                                    ),
                                    ...groupCustomers.map(
                                       (customer: any) =>
                                          `customer-${customer.Id}`
                                    )
                                 ]
                                 : selected || [
                                    ...userSites.map(
                                       (site: any) => `site-${site.Id}`
                                    ),
                                    ...userCustomers.map(
                                       (customer: any) =>
                                          `customer-${customer.Id}`
                                    )
                                 ]
                           }
                           onChange={(event: ZListBoxChangeEvent) => {
                              setIsChangingAccess(true);
                              const { selected, deselected } = event;
                              let promises: any[] = [];
                              (selected as string[]).forEach((option: string) => {
                                 if (option.startsWith("site")) {
                                    promises.push(
                                       apiClient.post(`Administration/UserSite`, {
                                          UserId: selectedUser.Id,
                                          SiteId: option.split("-")[1]
                                       })
                                    );
                                 } else {
                                    promises.push(
                                       apiClient.post(
                                          `Administration/UserCustomer`,
                                          {
                                             UserId: selectedUser.Id,
                                             CustomerId: option.split("-")[1]
                                          }
                                       )
                                    );
                                 }
                              });
                              (deselected as string[]).forEach(
                                 (option: string) => {
                                    if (option.startsWith("site")) {
                                       promises.push(
                                          apiClient.delete(
                                             `Administration/UserSite`,
                                             {
                                                params: {
                                                   userId: selectedUser.Id,
                                                   siteId: option.split("-")[1]
                                                }
                                             }
                                          )
                                       );
                                    } else {
                                       promises.push(
                                          apiClient.delete(
                                             `Administration/UserCustomer`,
                                             {
                                                params: {
                                                   userId: selectedUser.Id,
                                                   customerId: option.split("-")[1]
                                                }
                                             }
                                          )
                                       );
                                    }
                                 }
                              );
                              Promise.all(promises)
                                 .then(() => setSelected(event.newState))
                                 .catch(error => {
                                    setSelectedUser(null);
                                    setSelectedGroup(null);
                                    displayAndLogErrorWithMessage(
                                       error,
                                       "EditAccessErrorMessage",
                                       issueAlert
                                    );
                                 })
                                 .finally(() => {
                                    setIsChangingAccess(false);
                                 });
                           }}
                        />
                     </>
                  ) : groupSitesPending ||
                     groupCustomersPending ||
                     userSitesPending ||
                     userCustomersPending ||
                     isUserGroupAdminPending ? (
                        <ZLoadingMask containerSelector={".k-window"} />
                     ) : (
                        <></>
                     )}
               {isChangingAccess && (
                  <ZLoadingMask containerSelector={"#ListBoxWrapper"} />
               )}
            </ListBoxWrapper>
         </PopupContainer>
      </ZPopup>
   );
};

const ConfirmPasswordResetPopup = (props: ConfirmResetPasswordProps) => {
   const { resetPasswordUser, setResetPasswordUser, config } = props;
   const { apiClient } = useAppContext();
   const PopupContainer = styled.div`
      text-align: left;
   `;
   const { issueAlert } = useAlerts();
   const { languageId } = React.useContext(llContext);
   const serviceProApiUrl = config.serviceProApiUrl;
   const brand = config?.brand?.name?.toLowerCase() || "";
   const hermesBrand = brand.contains("pulse") ? "trimblepulse" : "portal";

   return (
      <ZPopup
         title="ResetPassword"
         localize={true}
         visible={true}
         width={600}
         onClose={() => {
            setResetPasswordUser(null);
         }}
      >
         <PopupContainer id="PopupContainer">
            <p>
               {ll({
                  key: "ConfirmResetPassword",
                  languageId
               }).format(resetPasswordUser.EmailAddress)}
            </p>
            <ButtonsWrapper>
               <CancelButtonWrapper>
                  <ZButton onClick={() => setResetPasswordUser(null)}>
                     Cancel
                  </ZButton>
               </CancelButtonWrapper>
               <AddButtonWrapper>
                  <ZButton
                     primary
                     onClick={(e: React.MouseEvent) => {
                        apiClient
                           .request({
                              data: {
                                 EmailAddress: resetPasswordUser.EmailAddress,
                                 ChangePasswordUrl: document.location.origin,
                                 Brand: hermesBrand
                              },
                              baseURL: `${serviceProApiUrl}/api`,
                              method: "post",
                              url: `Password/RequestReset`
                           })
                           .catch(error => {
                              displayAndLogErrorWithMessage(
                                 error,
                                 "RequestPasswordResetFailed",
                                 issueAlert
                              );
                           })
                           .finally(() => {
                              setResetPasswordUser(null);
                           });
                     }}
                  >
                     Reset
                  </ZButton>
               </AddButtonWrapper>
            </ButtonsWrapper>
         </PopupContainer>
      </ZPopup>
   );
};

const RemoveUserFromGroupPopup = (props: RemoveFromGroupPopupProps) => {
   const {
      selectedRemoveUser,
      setSelectedRemoveUser,
      groups,
      refetchUsers,
      userIsAdmin
   } = props;
   const [selectedGroup, setSelectedGroup] = React.useState<string | null>(
      null
   );

   const { apiClient } = useAppContext();
   const [isChangingAccess, setIsChangingAccess] = React.useState<boolean>(
      false
   );
   const [shouldRefetch, setShouldRefetch] = React.useState<boolean>(false);

   const PopupContainer = styled.div`
      text-align: left;
   `;
   const { issueAlert } = useAlerts();

   return (
      <ZPopup
         title={`${selectedRemoveUser.FirstName} ${selectedRemoveUser.LastName} - ${selectedRemoveUser.EmailAddress}`}
         localize={false}
         visible={true}
         width={600}
         onClose={() => {
            setSelectedRemoveUser(null);
            setSelectedGroup(null);
            refetchUsers(shouldRefetch);
         }}
      >
         <PopupContainer id="PopupContainer">
            <ZComboBox
               data={groups}
               value={selectedGroup}
               label={"Group"}
               onChange={e => {
                  setSelectedGroup(e.value);
               }}
            />
            <ZButton
               primary
               disabled={!selectedGroup}
               onClick={(e: React.MouseEvent) => {
                  if (
                     selectedGroup &&
                     selectedRemoveUser &&
                     selectedRemoveUser.Id
                  ) {
                     setIsChangingAccess(true);
                     apiClient
                        .delete(`Administration/GroupMembership`, {
                           params: {
                              groupName: selectedGroup,
                              userId: selectedRemoveUser.Id
                           }
                        })
                        .then(() => {
                           _.pull(groups, selectedGroup);
                           setSelectedGroup(null);
                           if (!userIsAdmin) {
                              setShouldRefetch(true);
                           }
                        })
                        .catch(error => {
                           setSelectedRemoveUser(null);
                           setSelectedGroup(null);
                           displayAndLogErrorWithMessage(
                              error,
                              "RemoveUserFromGroupErrorMessage",
                              issueAlert
                           );
                        })
                        .finally(() => {
                           setIsChangingAccess(false);
                        });
                  }
               }}
            >
               RemoveUserFromGroup
            </ZButton>
            {isChangingAccess && (
               <ZLoadingMask containerSelector={"#PopupContainer"} />
            )}
         </PopupContainer>
      </ZPopup>
   );
};

const ContainerWrapper = styled.div`
   text-align: left;
   margin-left: 30px;
`;

const CancelButtonWrapper = styled.div`
   position: absolute;
   display: inline-block;
   left: 5px;
   bottom: 5px;
`;

const AddButtonWrapper = styled.div`
   position: absolute;
   display: inline-block;
   right: 5px;
   bottom: 5px;
`;

const ButtonsWrapper = styled.div`
   margin-top: 30px;
`;
const IconSpacing = styled.div`
   padding-right: 14px;
   display: inline;
`;

interface AddUserPopupProps {
   groups: any[];
   isUserAdmin: boolean;
   onClose: (shouldRefetch: boolean) => void;
}

const AddUserPopup = (props: AddUserPopupProps) => {
   const { groups, onClose, isUserAdmin } = props;
   const { apiClient } = useAppContext();
   const [requireGroup, setRequireGroup] = React.useState<boolean>(false);
   const [email, setEmail] = React.useState<string>("");
   const { user, pending: userPending } = useFindUser(email);
   const [loading, setLoading] = React.useState<boolean>(false);
   const firstNameRef = React.useRef<any>();
   const lastNameRef = React.useRef<any>();
   const { issueAlert } = useAlerts();

   React.useEffect(() => {
      if (user) {
         firstNameRef.current.setValue(user.FirstName);
         lastNameRef.current.setValue(user.LastName);
      }
   }, [user]);

   return (
      <ZPopup
         title={"AddUser"}
         width={400}
         visible={true}
         onClose={() => onClose(false)}
      >
         <ZForm
            onFormSubmit={(result) => {
               result.Admin = result.GroupName ? !!result.Admin : undefined;
               result.GroupName = result.GroupName || undefined;
               setLoading(true);
               apiClient
                  .post("Administration/CreateUser", result)
                  .then(() => {
                     onClose(true);
                  })
                  .catch((error) => {
                     if (
                        error?.data?.Errors?.General.length > 0 &&
                        error?.data?.Errors?.General[0]?.ErrorCode === 40000
                     ) {
                        issueAlert({
                           type: "warning",
                           message: "UserAlreadyHasAccess"
                        });
                     } else {
                        displayAndLogErrorWithMessage(
                           error,
                           "AddUserErrorMessage",
                           issueAlert
                        );
                     }
                     setLoading(false);
                  });
            }}
            render={(formContext: FormContextValue) => (
               <ContainerWrapper>
                  <ZContainer editable={false}>
                     <ZCheckbox
                        label={"Admin"}
                        onChange={(e) =>
                           setRequireGroup((e.target.value as any).value)
                        }
                     />
                     <ZTextBox
                        id={"Email"}
                        label={"EmailAddress"}
                        width={250}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                           setEmail(e.target.value);
                        }}
                        required
                        ignoreFVS
                     />
                     <ZTextBox
                        id={"FirstName"}
                        label={"FirstName"}
                        ref={firstNameRef}
                        width={250}
                        disabled={!!user}
                        required
                        ignoreFVS
                     />
                     <ZTextBox
                        id={"LastName"}
                        label={"LastName"}
                        ref={lastNameRef}
                        width={250}
                        disabled={!!user}
                        required
                        ignoreFVS
                     />
                     <ZComboBox
                        id={"GroupName"}
                        data={groups || []}
                        label={"Group"}
                        width={250}
                        required={requireGroup || !isUserAdmin}
                     />
                  </ZContainer>
                  <ButtonsWrapper>
                     <CancelButtonWrapper>
                        <ZButton onClick={() => onClose(false)}>Cancel</ZButton>
                     </CancelButtonWrapper>
                     <AddButtonWrapper>
                        <ZButton
                           onClick={() => {
                              formContext.submit && formContext.submit();
                           }}
                           disabled={userPending}
                           primary
                        >
                           Add
                        </ZButton>
                     </AddButtonWrapper>
                  </ButtonsWrapper>
               </ContainerWrapper>
            )}
         ></ZForm>
         {!groups || loading ? (
            <ZLoadingMask containerSelector=".k-window" />
         ) : (
            <></>
         )}
      </ZPopup>
   );
};

const ModifyAccessButton = styled.div`
   color: ${props => props.theme["app-link-color"]};
   display: inline;
   font-size: 16px;
   cursor: pointer;
   :hover {
      color: ${props => props.theme["hover-color"]};
   }
   :active {
      color: ${props => props.theme["active-color"]};
   }
`;

const UserHeader = styled.div`
   display: inline-block;
   width: 100%;
   position: relative;
`;

const AddUserWrapper = styled.div`
   position: absolute;
   display: inline-block;
   padding: 5px;
   right: 0;
   bottom: 0;
`;

export const Users = (props: any) => {
   const { isWide } = props;
   const Wrapper = React.useMemo(
      () => styled.div`
         margin-top: ${isWide ? `60px` : "55px"};
         text-align: left;
      `,
      [isWide]
   );
   const isUserAdmin = isAdmin();
   const {
      users,
      pending: usersPending,
      refetch: refetchUsers
   } = usePortalUsers();
   const [selectedUser, setSelectedUser] = React.useState<any>(null);
   const [selectedRemoveUser, setSelectedRemoveUser] = React.useState<any>(
      null
   );
   const [resetPasswordUser, setResetPasswordUser] = React.useState<any>(null);
   const { groups: userGroups, pending: userGroupsPending } = useGroups(
      selectedUser && selectedUser.Id
   );
   const {
      groups: removeUserGroups,
      pending: removeUserGroupsPending
   } = useGroups(selectedRemoveUser && selectedRemoveUser.Id);
   const { groups } = useGroups();
   const [filterText, setFilterText] = React.useState<string>("");
   const debouncedFilterText = useDebounce(filterText, 200);
   const [addUserVisible, setAddUserVisible] = React.useState<boolean>(false);
   const config = useConfiguration();

   return (
      <Wrapper>
         <UserHeader>
            <ZTextBox
               icon={"search"}
               value={filterText}
               onChange={(event?: React.ChangeEvent<HTMLInputElement>) =>
                  setFilterText(`${(event && event.target.value) || ""}`)
               }
               placeholder={"SearchByNameOrEmail"}
               width={300}
            />
            <AddUserWrapper>
               <ZButton onClick={() => setAddUserVisible(true)}>
                  AddUser
               </ZButton>
            </AddUserWrapper>
         </UserHeader>
         <ZGrid
            data={users}
            pending={
               usersPending || userGroupsPending || removeUserGroupsPending
            }
            style={{
               width: "100%",
               height: 500
            }}
            filter={{
               logic: "or",
               filters: [
                  {
                     field: "FirstName",
                     operator: "contains",
                     value: debouncedFilterText
                  },
                  {
                     field: "LastName",
                     operator: "contains",
                     value: debouncedFilterText
                  },
                  {
                     field: "EmailAddress",
                     operator: "contains",
                     value: debouncedFilterText
                  }
               ]
            }}
            columns={[
               {
                  title: "FirstName",
                  field: "FirstName",
                  filter: "text",
                  columnMenu: null
               },
               {
                  title: "LastName",
                  field: "LastName",
                  filter: "text",
                  columnMenu: null
               },
               {
                  title: "EmailAddress",
                  field: "EmailAddress",
                  filter: "text",
                  columnMenu: null
               },
               {
                  sortable: false,
                  width: 130,
                  columnMenu: null,
                  cell: (cellData: any) => (
                     <td
                        className={cellData.className}
                        colSpan={cellData.colSpan}
                     >
                        <ModifyAccessButton
                           onClick={() => {
                              setSelectedUser(cellData.dataItem);
                           }}
                        >
                           <FontAwesomeIcon icon="pencil" />
                        </ModifyAccessButton>
                        <IconSpacing />
                        <ModifyAccessButton
                           onClick={() => {
                              setSelectedRemoveUser(cellData.dataItem);
                           }}
                        >
                           <FontAwesomeIcon icon="users-slash" />
                        </ModifyAccessButton>
                        <IconSpacing />
                        <ModifyAccessButton
                           onClick={() => {
                              setResetPasswordUser(cellData.dataItem);
                           }}
                        >
                           <FontAwesomeIcon icon="unlock" />
                        </ModifyAccessButton>
                     </td>
                  )
               }
            ]}
            onDoubleClick={(event, dataItem) => {
               setSelectedUser(dataItem.dataItem);
            }}
            scrollable={"scrollable"}
            pageable={true}
            pageSize={10}
         />
         {selectedUser && userGroups ? (
            isUserAdmin ? (
               <CorrelationGroup>
                  <AdminEditAccessPopup
                     selectedUser={selectedUser}
                     setSelectedUser={setSelectedUser}
                     groups={userGroups}
                  />
               </CorrelationGroup>
            ) : (
                  <CorrelationGroup>
                     <GroupAdminEditAccessPopup
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        groups={userGroups}
                     />
                  </CorrelationGroup>
               )
         ) : (
               <></>
            )}
         {selectedRemoveUser && removeUserGroups ? (
            <RemoveUserFromGroupPopup
               selectedRemoveUser={selectedRemoveUser}
               setSelectedRemoveUser={setSelectedRemoveUser}
               groups={removeUserGroups}
               refetchUsers={(shouldRefetch: boolean) => {
                  if (shouldRefetch) {
                     refetchUsers();
                  }
               }}
               userIsAdmin={isUserAdmin}
            />
         ) : (
               <></>
            )}
         {addUserVisible ? (
            <AddUserPopup
               groups={groups}
               isUserAdmin={isUserAdmin}
               onClose={(shouldRefetch: boolean) => {
                  if (shouldRefetch) {
                     refetchUsers();
                  }
                  setAddUserVisible(false);
               }}
            />
         ) : (
               <></>
            )}
         {resetPasswordUser ? (
            <ConfirmPasswordResetPopup
               resetPasswordUser={resetPasswordUser}
               setResetPasswordUser={setResetPasswordUser}
               config={config}
            />
         ) : (
               <></>
            )}
      </Wrapper>
   );
};
