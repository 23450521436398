import { guid } from "@progress/kendo-react-common";
import Axios, { AxiosRequestConfig } from "axios";
import { PortalConfig } from "../hooks/useConfiguration";
import { getExpandsFromSelects } from "@msidata/zeact";

export interface APIError {
   code: number;
   message: string;
   details: string | null;
}

const fvsCorrelationGroupId = guid();

const addHeaders = (config: AxiosRequestConfig) => {
   config.headers["Correlation-Id"] =
      config.headers["Correlation-Id"] || guid();
   const token = JSON.parse(localStorage.getItem("authResult") || "{}")
      .accessToken;
   config.headers["Authorization"] = `Bearer ${token}`;
   if (config.url?.contains("FieldVisibilitySettings")) {
      config.headers["Correlation-Group-Id"] = fvsCorrelationGroupId;
   }
   if (!config.url?.toLowerCase().contains("selectcompany/usercompanies")) {
      const companyId = localStorage.getItem("Company-Id") || "-1";
      config.headers["Company-Id"] = companyId;
   }
   return config;
};

const transformOData = (config: AxiosRequestConfig) => {
   if (!config.params) {
      return config;
   }
   const selects: string | string[] | undefined =
      config.params["$select"] || config.params["select"] || undefined;
   config.params["$select"] =
      selects === undefined
         ? undefined
         : typeof selects === "object"
         ? selects.join(",")
         : selects;
   delete config.params["select"];
   const expands: string | string[] =
      config.params["$expand"] ||
      config.params["expand"] ||
      (selects &&
         getExpandsFromSelects(
            typeof selects === "string" ? selects.split(",") : selects
         )) ||
      [];
   config.params["$expand"] =
      typeof expands === "string"
         ? expands
         : expands.length > 0
         ? expands.join(",")
         : undefined;
   delete config.params["expand"];

   if (config.params["top"]) {
      config.params["$top"] = config.params["top"];
      delete config.params["top"];
   }
   if (config.params["skip"]) {
      config.params["$skip"] = config.params["skip"];
      delete config.params["skip"];
   }
   if (config.params["filter"]) {
      config.params["$filter"] = config.params["filter"];
      delete config.params["filter"];
   }

   return config;
};

const handleResponse = (response: any) => response;
const handleError = (error: any) => {
   if (error && error.response && error.response.status === 401) {
      if (
         error.config &&
         error.config.url &&
         error.config.url.endsWith("IsAdmin")
      ) {
         return Promise.reject(error.response ? error.response : error);
      } else {
         localStorage.clear();
         document.location.replace("/Login");
      }
   }
   return Promise.reject(error.response ? error.response : error);
};

export function useServiceProAPI(appConfig: PortalConfig) {
   const { serviceProApiUrl } = appConfig;
   const requestConfig: AxiosRequestConfig = {
      baseURL: `${serviceProApiUrl}/api/portal`
   };
   let ServiceProAPIClient = Axios.create();
   ServiceProAPIClient.interceptors.request.use(addHeaders);
   ServiceProAPIClient.interceptors.request.use(transformOData);
   ServiceProAPIClient.interceptors.response.use(handleResponse, handleError);

   Object.assign(ServiceProAPIClient.defaults, requestConfig);
   (ServiceProAPIClient as any).CancelToken = Axios.CancelToken;
   (ServiceProAPIClient as any).isCancel = Axios.isCancel;

   if (window && typeof window === "object") {
      if (!("zeact" in window)) {
         (window as any).zeact = {};
      }
      (window as any).zeact.apiClient = ServiceProAPIClient;
   }
   return ServiceProAPIClient;
}
