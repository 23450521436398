import * as React from "react";
import {
   ZPopup,
   ZLoadingMask,
   ZPortalLabel,
   ll,
   llContext,
   ErrorBoundary
} from "@msidata/zeact";
import styled from "styled-components";
import { useConfiguration } from "../hooks/useConfiguration";

interface AboutPopupProps {
   visible?: boolean;
   onClose?: (e?: any) => void;
   className?: string;
}

const About = styled.div`
   display: grid;
   grid-gap: 0px;
   margin: 20px;
`;
export const AboutPopup = styled((props: AboutPopupProps) => {
   const [updatingAbout] = React.useState(false);
   const config = useConfiguration();
   const { languageId } = React.useContext(llContext);
   const supportEmail =
      config.brand?.supportEmail || "serviceprosupport@msidata.com";
   const Logo = styled.img.attrs(props => {
      return { src: props.theme["app-logo-color-url"] };
   })`
      height: 90px;
      width: 90px;
      justify-self: center;
   `;

   const SupportEmailLink = styled.div`
      position: relative;
      top: -7px;
      left: 10px;
   `;

   const CopyrightNotice = styled.div`
      margin-top: 30px;
      justify-self: center;
   `;
   return (
      <ErrorBoundary>
         <div id={`about-popup`}>
            <ZPopup
               title={"PortalTitle"}
               visible={true}
               onClose={() => {
                  props.onClose && props.onClose();
               }}
               maximizeButton={() => null}
               minimizeButton={() => null}
               className={props.className}
            >
               <About>
                  <Logo />
                  <ZPortalLabel header={"Version"} content={config.version} />
                  <ZPortalLabel header={"Support"} content={" "} />
                  <SupportEmailLink>
                     <a
                        href={`mailto:${supportEmail}?subject=Portal v.${config.version}`}
                     >
                        {supportEmail}
                     </a>
                  </SupportEmailLink>
                  <CopyrightNotice>
                     <span>© {new Date().getFullYear()}</span>{" "}
                     <span>
                        {ll({
                           key: config.brand?.name || "EmptyString",
                           languageId
                        })}
                     </span>
                  </CopyrightNotice>
               </About>
            </ZPopup>
            {updatingAbout && (
               <ZLoadingMask containerSelector={`#About-popup .k-window`} />
            )}
         </div>
      </ErrorBoundary>
   );
})`
   align-items: center;
   & .z-popup-action-buttons {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
   }
   & .z-popup-ok-button {
      margin-top: 15px;
   }
   text-align: initial;
`;

export default AboutPopup;
