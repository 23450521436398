import React from "react";
import {
   OrderSummary as OrderSummaryComponent,
   ZButton,
   ZLoadingMask,
   ZPopup,
   ZTooltip,
   ll,
   useLanguage
} from "@msidata/zeact";
import {
   getBooleanOption,
   useCompanyInfoContext
} from "../data/CompanyInfoContext";
import styled from "styled-components";
import { useAppContext } from "../data/AppContext";
import { isAdmin } from "../hooks/useUser";

const Popup = styled(ZPopup)`
   .k-window {
      position: fixed;
   }

   .k-window-content {
      padding-bottom: 0;
   }
`;

const OrderSummary = (props: { orderId: number; onClose: () => void }) => {
   const [printing, setPrinting] = React.useState<boolean>(false),
      [maximized, setMaximized] = React.useState(false),
      languageId = useLanguage(),
      Button = styled(ZButton)`
         float: right;
         margin-top: 15px !important;
         box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
      `,
      OrderSummaryContainer =
         window.innerWidth > window.innerHeight
            ? styled.div`
                 height: ${maximized
                    ? window.innerHeight - 143
                    : window.innerHeight * 0.9 - 143}px;
                 overflow-y: scroll;
                 overflow-x: hidden;
              `
            : styled.div`
                 height: ${window.innerHeight}px;
                 width: ${window.innerWidth}px;
                 overflow-y: scroll;
                 overflow-x: hidden;
                 position: fixed;
                 top: 0;
                 left: 0;
                 z-index: 10001;
                 background-color: white;
              `,
      PrintingMaskWrapper = styled.div`
         position: fixed;
         top: 0;
         left: 0;
         width: ${window.innerWidth};
         height: ${window.innerHeight};
      `,
      MaskWrapper = styled.div`
         width: 100%;
         height: 100%;
      `,
      OffScreenWrapper = styled.div`
         position: absolute;
         bottom: 10000px;
         left: 10000px;
      `,
      { apiClient, isMobile } = useAppContext(),
      CloseWrapper = styled.div`
         position: fixed;
         top: 10px;
         right: ${isMobile ? "10px" : "20px"};
      `,
      PrintWrapper = styled.div`
         position: fixed;
         bottom: 10px;
         right: ${isMobile ? "10px" : "20px"};
      `,
      [data, setData] = React.useState<any>(null),
      editableFVS = isAdmin(),
      { companyInfo } = useCompanyInfoContext(),
      { codes, currency: companyCurrency, logo: logoUrl } = companyInfo,
      isVista = getBooleanOption("IsVista", false);

   if (isVista && data) {
      const udfs = (data as any).UserDefinedFields;
      const udls = (data as any).UserDefinedLists;
      const udf = udfs.find((udf: any) => udf.BindName === "UDF_Price_Method");
      const udl =
         udf && udls.find((udl: any) => udl.Id === udf.UserDefinedListId);

      if (udl) {
         (data as any).Order.OrderSegment.forEach((os: any) => {
            const priceMethod = udl?.Values?.find(
               (udlv: any) => `${udlv.Id}` === os["UDF_Price_Method"]
            )?.CodeNumber;

            if (priceMethod === "N" || priceMethod === "F") {
               os.OrderLine = [];
            }
         });
      }
   }

   React.useEffect(() => {
      apiClient
         .get(`Order/GetOrderSummaryData`, {
            params: {
               orderId: props.orderId
            }
         })
         .then(response => {
            setData(response.data.d.results);
         });
   }, [props.orderId, apiClient]);
   
   if (isVista) {
      if (data?.Order.LastIntegratedDateTime === null) {
      data.Order.OrderNumber = ll({key: "NewOrder", languageId});
      data.Order.OrderSegment.forEach((segment: any) => segment.SegmentNumber = ll({key: "NewOrderSegment", languageId}))
      } else if (data) {
         data.Order.OrderSegment.forEach((segment: any) => {
            if(!isNaN(segment.SegmentNumber) && parseInt(segment.SegmentNumber) < 0)
            segment.SegmentNumber = ll({key: "NewOrderSegment", languageId});
         })
         if(!isNaN(data.Order.OrderNumber) && parseInt(data.Order.OrderNumber) < 0)
            data.Order.OrderNumber = ll({key: "NewOrder", languageId});
      }
   }
   
   return (
      <>
         {printing && data ? (
            <OffScreenWrapper>
               <OrderSummaryComponent
                  print={true}
                  data={data}
                  logoUrl={logoUrl}
                  donePrinting={() => setPrinting(false)}
                  companyCurrency={companyCurrency}
                  fvsLevelData={
                     codes.PortalFVSLevel.length > 0
                        ? codes.PortalFVSLevel
                        : undefined
                  }
                  fvsLevelId={
                     data.Order.OrderStatus.PortalFVSLevelId
                        ? data.Order.OrderStatus.PortalFVSLevelId
                        : undefined
                  }
               ></OrderSummaryComponent>
            </OffScreenWrapper>
         ) : (
            undefined
         )}
         {window.innerWidth > window.innerHeight ? (
            <Popup
               visible={true}
               onClose={() => {
                  props.onClose();
               }}
               allowMaximize={true}
               onMaximizeChange={(maximize: boolean) => setMaximized(maximize)}
               title={"OrderSummary"}
               width={window.innerWidth * 0.6 + 80}
               height={window.innerHeight * 0.9}
            >
               <OrderSummaryContainer>
                  {data ? (
                     <OrderSummaryComponent
                        width={
                           maximized
                              ? window.innerWidth - 80
                              : window.innerWidth * 0.6
                        }
                        data={data}
                        logoUrl={logoUrl}
                        editableFVS={editableFVS}
                        companyCurrency={companyCurrency}
                        fvsLevelData={
                           codes.PortalFVSLevel.length > 0
                              ? codes.PortalFVSLevel
                              : undefined
                        }
                        fvsLevelId={
                           data.Order.OrderStatus.PortalFVSLevelId
                              ? data.Order.OrderStatus.PortalFVSLevelId
                              : undefined
                        }
                     ></OrderSummaryComponent>
                  ) : (
                     <div id={"OrderSummary"}>
                        <ZLoadingMask
                           containerSelector={"#OrderSummary"}
                        ></ZLoadingMask>
                     </div>
                  )}
               </OrderSummaryContainer>
               <Button
                  onClick={() => {
                     setPrinting(true);
                  }}
                  disabled={printing}
               >
                  Print
               </Button>
               {printing && <ZLoadingMask containerSelector={`.k-window`} />}
            </Popup>
         ) : (
            <OrderSummaryContainer>
               {data ? (
                  <OrderSummaryComponent
                     width={
                        isMobile ? window.innerWidth : window.innerWidth - 20
                     }
                     data={data}
                     mobile={isMobile}
                     logoUrl={logoUrl}
                     editableFVS={editableFVS}
                     companyCurrency={companyCurrency}
                     fvsLevelData={
                        codes.PortalFVSLevel.length > 0
                           ? codes.PortalFVSLevel
                           : undefined
                     }
                     fvsLevelId={
                        data.Order.OrderStatus.PortalFVSLevelId
                           ? data.Order.OrderStatus.PortalFVSLevelId
                           : undefined
                     }
                  ></OrderSummaryComponent>
               ) : (
                  <MaskWrapper id={"OrderSummary"}>
                     <ZLoadingMask
                        containerSelector={"#OrderSummary"}
                     ></ZLoadingMask>
                  </MaskWrapper>
               )}
               <PrintWrapper>
                  <Button
                     onClick={() => {
                        setPrinting(true);
                     }}
                     disabled={printing}
                  >
                     Print
                  </Button>
               </PrintWrapper>
               <CloseWrapper
                  onClick={() => {
                     props.onClose();
                  }}
               >
                  <ZTooltip title={"Close"}>
                     <span className="k-icon k-i-close" />
                  </ZTooltip>
               </CloseWrapper>
               {printing && (
                  <PrintingMaskWrapper id="OrderSummaryPrintingMask">
                     <ZLoadingMask
                        containerSelector={`#OrderSummaryPrintingMask`}
                     />
                  </PrintingMaskWrapper>
               )}
            </OrderSummaryContainer>
         )}
      </>
   );
};

export default OrderSummary;
