import {
   CacheTimes,
   ReactQuery,
   useAlerts,
   displayAndLogErrorWithMessage
} from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";
import React from "react";

interface OrderBadgeCountResult {
   All: number;
   InProgress: number;
   Completed: number;
   OnHold: number;
   Scheduled: number;
}

export interface UseOrderBadgeCountsHookReturnValue {
   results: OrderBadgeCountResult;
   refresh: (
      args?:
         | {
              force?: boolean | undefined;
              throwOnError?: boolean | undefined;
           }
         | undefined
   ) => Promise<Partial<OrderBadgeCountResult>>;
   error: any;
   pending: boolean;
}

const queryConfig = {
   staleTime: CacheTimes.FifteenMinutes,
   suspense: false,
   retryCount: 2
};

export const useOrderBadgeCounts = () => {
   const { apiClient } = useAppContext();
   const { issueAlert } = useAlerts();
   const fetchOrderCounts = React.useCallback(() => {
      return new Promise<Partial<OrderBadgeCountResult>>((resolve, reject) =>
         apiClient
            .request({
               method: "GET",
               url: `Order/GetOrderBadgeCounts`
            })
            .then(response => {
               if (response.status === 200) {
                  resolve(response.data.d.results);
               } else {
                  throw response;
               }
            })
            .catch(error => {
               displayAndLogErrorWithMessage(
                  error,
                  "GetOrderBadgeCountsErrorMessage",
                  issueAlert
               );

               resolve({});
            })
      );
   }, [apiClient, issueAlert]);

   const { data, error, refetch, isFetching } = ReactQuery.useQuery(
      "order-badge-counts",
      fetchOrderCounts,
      queryConfig
   );

   let results = data || {};
   const All = results.All || 0;
   const InProgress = results.InProgress || 0;
   const Completed = results.Completed || 0;
   const OnHold = results.OnHold || 0;
   const Scheduled = results.Scheduled || 0;

   return {
      results: {
         All,
         InProgress,
         Completed,
         OnHold,
         Scheduled
      },
      error,
      refresh: refetch,
      pending: isFetching
   };
};
