import * as React from "react";
import {
   ZBanner,
   ZBannerItem,
   ZBannerSubItem,
   ZMenuItem,
   ll,
   useAuthContext,
   useLanguage
} from "@msidata/zeact";
import { useHistory, useLocation } from "react-router-dom";
import styled, { ThemeProvider, ThemeContext } from "styled-components";
import { Route } from "react-router-dom";
import { isAdmin, useUser, isGroupAdmin } from "../hooks/useUser";
import { useConfiguration } from "../hooks/useConfiguration";
import UserPreferencesPopup from "./UserPreferencesPopup";
import AboutPopup from "./AboutPopup";
import {
   getOptionValue,
   useCompanyInfoContext
} from "../data/CompanyInfoContext";

const Banner = (props: any) => {
   const user = useUser();
   const config = useConfiguration();
   const history = useHistory();
   const location = useLocation();
   const languageId = useLanguage();
   const { companyInfo } = useCompanyInfoContext();
   const logoUrl = companyInfo?.logo;
   const theme = React.useContext(ThemeContext);
   const portalBannerColor = getOptionValue(
      "PortalBannerColor",
      theme["banner-background-color"]
   );
   const [redirect, setRedirect] = React.useState<boolean>(false);
   const productName = config.brand?.name || "ServiceProPortal";
   const localizedProductName = ll({ key: productName, languageId });
   const isUserAdmin = isAdmin();
   const isUserGroupAdmin = isGroupAdmin();
   const basePath = location.pathname
      .replace("/About", "")
      .replace("/UserPreferences", "");

   const RedirectToLogout = (props: any) => {
      useAuthContext().logout();
      return <></>;
   };
   const useSwitchTenant = (): React.ReactNode => {
      const numOfCompaniesLS = localStorage.getItem("numOfCompanies") || "1";
      const numOfCompanies = parseInt(numOfCompaniesLS, 10);
      if (numOfCompanies > 1) {
         return (
            <ZMenuItem
               onClick={(e: React.MouseEvent) =>
                  history.push("/SelectTenant")
               }
            >
               {ll({ key: "SwitchTenant", languageId })}
            </ZMenuItem>
         );
      }
      return React.Fragment;
   }
   return (
      <span className={props.className}>
         {redirect ? <RedirectToLogout /> : <></>}
         <ThemeProvider
            theme={{
               ...theme,
               "app-logo-color-url": logoUrl || theme["app-logo-color-url"],
               "banner-background-color": portalBannerColor
            }}
         >
            <ZBanner
               {...props}
               user={{
                  name:
                     user?.FirstName || user?.LastName
                        ? `${user?.FirstName || ""} ${user?.LastName ||
                           ""}`.trim()
                        : user?.EmailAddress || ll("User")
               }}
               menuItems={[
                  <ZMenuItem
                     onClick={(e: React.MouseEvent) => {
                        history.push("/Customers");
                     }}
                  >
                     {ll({ key: "Customers", languageId })}
                  </ZMenuItem>,
                  <ZMenuItem
                     onClick={(e: React.MouseEvent) => {
                        history.push("/Sites");
                     }}
                  >
                     {ll({ key: "Sites", languageId })}
                  </ZMenuItem>,
                  <ZMenuItem
                     onClick={(e: React.MouseEvent) => {
                        history.push(`${basePath}/About`);
                     }}
                  >
                     {ll({
                        key: "AboutMenuItemTitle",
                        languageId,
                        variables: [localizedProductName]
                     })}
                  </ZMenuItem>,
                  <ZMenuItem
                     onClick={(e: React.MouseEvent) => {
                        history.push(`${basePath}/UserPreferences`);
                     }}
                  >
                     {ll({ key: "UserPreferences", languageId })}
                  </ZMenuItem>,
                  useSwitchTenant(),
                  <ZMenuItem
                     onClick={(e: React.MouseEvent) => setRedirect(true)}
                  >
                     {ll({ key: "LogOut", languageId })}
                  </ZMenuItem>
               ]}
            >
               <ZBannerItem text={"Order"} link="/Order">
                  <ZBannerSubItem
                     text={"OrderNotes"}
                     link="/Order/OrderNotes"
                  />
               </ZBannerItem>
               <ZBannerItem text={"Equipment"} link="/Equipment" />
               <ZBannerItem text={"Inspection"} link="/Inspection" />
               {isUserAdmin || isUserGroupAdmin ? (
                  <ZBannerItem text={"Users"} link="/Users" />
               ) : (
                     <></>
                  )}
            </ZBanner>
            <Route
               path={`${basePath}/About`}
               render={() => (
                  <AboutPopup
                     onClose={() => {
                        history.push(basePath);
                     }}
                  />
               )}
            />
            <Route
               path={`${basePath}/UserPreferences`}
               render={() => (
                  <UserPreferencesPopup
                     onClose={() => {
                        history.push(basePath);
                     }}
                  />
               )}
            />
         </ThemeProvider>
      </span>
   );
};

const StyledBanner = styled(Banner)`
   text-align: initial;
`;

export const withBanner = (Component: () => JSX.Element): JSX.Element => (
   <>
      <StyledBanner />
      <Component />
   </>
);

export default StyledBanner;
