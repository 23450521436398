import * as React from "react";
import {
   ZLogin,
   ZSelectTenant,
   ZLogout,
   portal,
   useAuthContext,
   useFaviconFromTheme,
   useLayoutContext,
   SessionTimer,
   ChangePassword,
   Footer,
   ModalWrapper,
   Background,
   CorrelationGroup
} from "@msidata/zeact";
import { Customers } from "./components/Customers";
import { withBanner } from "./components/Banner";
import Tabs from "./components/Tabs";
import Equipment from "./components/Equipment";
import OrderNotesPage from "./components/OrderNotes";
import InspectionPage from "./components/Inspection";
import {
   BrowserRouter as Router,
   Redirect,
   Route,
   Switch,
   useHistory
} from "react-router-dom";
import { isAdmin, isGroupAdmin } from "./hooks/useUser";
import { Sites } from "./components/Sites";
import { withMaxWidth } from "./hooks/withMaxWidth";
import { Users } from "./components/Users";
import { useConfiguration } from "./hooks/useConfiguration";
import { withCompanyInfo } from "./data/CompanyInfoContext";
import { withUserPreferences } from "./hooks/useUserPreferences";
import { getCookie, removeCookie } from "./util/utils";

const LoginRoute = () => <ZLogin theme={portal} />;

const LogoutRoute = () => <ZLogout />;

const SelectTenantRoute = () => {
   const config = useConfiguration();
   const { logout, setIsAuthenticated } = useAuthContext();
   const [returnComponent, setReturnComponent] = React.useState<JSX.Element>(<></>);
   const token = "spToken",
      companyId = "spCompanyId",
      numOfCompanies = "spNumOfCompanies";

   React.useEffect(() => {
      const spToken = getCookie(token);
      const spCompanyId = getCookie(companyId);
      const spNumOfCompanies = getCookie(numOfCompanies);
      const useCookie = spToken !== ""
         && spCompanyId !== ""
         && spNumOfCompanies !== "";

      if (useCookie) {
         removeCookie(token);
         removeCookie(companyId);
         removeCookie(numOfCompanies);
         localStorage.setItem("authResult", JSON.stringify({
            accessToken: spToken
         }))
         localStorage.setItem("Company-Id", spCompanyId);
         localStorage.setItem("numOfCompanies", spNumOfCompanies);
         localStorage.setItem("cookieUsed", useCookie.toString());
         setReturnComponent(<Redirect to={"/Order"} />);
      } else {
         setReturnComponent(
            config.loaded
               ? ZSelectTenant({
                  getTenantsEndpoint: `${config.serviceProApiUrl}/api/portal/SelectCompany/UserCompanies`,
                  redirectToSlug: "/Order",
                  clientId: "Portal",
                  handleAuth0Error: error => {
                     console.error(error);
                     let url = "/Login";
                     if (error.error) {
                        url += `#error=${error.error}`;
                        if (
                           error.description ||
                           error.errorDescription ||
                           error.error_description
                        ) {
                           url += `&error_description=${error.description ||
                              error.errorDescription ||
                              error.error_description}`;
                        }
                     }

                     document.location.replace(url);
                  },
                  handleAuth0Profile: profile => {
                     localStorage.setItem("authProfile", JSON.stringify(profile));
                     setIsAuthenticated(true);
                  },
                  onReturn: () => logout()
               })
               : <></>
         );
      }
   }, [config, logout, setIsAuthenticated]);

   return returnComponent;
};

const ChangePasswordRoute = () => (
   <>
      <Background>
         <ModalWrapper>
            <ChangePassword />
         </ModalWrapper>
      </Background>
      <Footer />
   </>
);

const viewMaxWidth: string = "1080px";

const Wrapper = (Component: React.FC) => {
   return React.useMemo(
      () =>
         withMaxWidth(viewMaxWidth, () =>
            withCompanyInfo(() =>
               withUserPreferences(() => withBanner(() => <Component />))
            )
         ),
      []
   );
};

const OrderPage = () => {
   const { isWide } = useLayoutContext();

   return (
      <CorrelationGroup>
         <Tabs isWide={isWide} />
      </CorrelationGroup>
   );
};
const OrderRoute = () => Wrapper(OrderPage);

const EquipmentPage = () => {
   const { isWide } = useLayoutContext();

   return (
      <CorrelationGroup>
         <Equipment isWide={isWide} />
      </CorrelationGroup>
   );
};
const EquipmentRoute = () => Wrapper(EquipmentPage);

const OrderNotesRoute = () =>
   Wrapper(() => (
      <CorrelationGroup>
         <OrderNotesPage />
      </CorrelationGroup>
   ));

const InspectionRoute = () =>
   Wrapper(() => (
      <CorrelationGroup>
         <InspectionPage />
      </CorrelationGroup>
   ));

const UsersPage = () => {
   const { isWide } = useLayoutContext();
   const isUserAdmin = isAdmin();
   const isUserGroupAdmin = isGroupAdmin();
   const history = useHistory();

   if (!isUserAdmin && !isUserGroupAdmin) {
      history.push("/Order");
   }

   return isUserAdmin || isUserGroupAdmin ? (
      <CorrelationGroup>
         <Users isWide={isWide} />
      </CorrelationGroup>
   ) : (
         <></>
      );
};
const UsersRoute = () => Wrapper(UsersPage);

const SitesPage = () => {
   const { isWide } = useLayoutContext();

   return (
      <CorrelationGroup>
         <Sites isWide={isWide} />
      </CorrelationGroup>
   );
};
const SitesRoute = () => Wrapper(SitesPage);

const CustomersPage = () => {
   const { isWide } = useLayoutContext();

   return (
      <CorrelationGroup>
         <Customers isWide={isWide} />
      </CorrelationGroup>
   );
};
const CustomersRoute = () => Wrapper(CustomersPage);

const DefaultRoute = () => {
   const history = useHistory();
   const { isAuthenticated } = useAuthContext();
   const lsCompanyId = localStorage.getItem("Company-Id") || "-1";
   const companyId = parseInt(lsCompanyId);

   if (companyId === -1) {
      history.push("/SelectTenant");
   }

   if (isAuthenticated) {
      history.push("/Order");
   } else {
      history.push("/Login");
   }

   return <></>;
};

const App = () => {
   useFaviconFromTheme();
   const nonProtectedRoutes = [
      "/login",
      "/continue",
      "/selecttenant",
      "/sendchangepassword",
      "/changepassword",
      "/logout"
   ];

   const sessionTimer = React.useMemo(
      () =>
         process.env.NODE_ENV === "test" ? null : (
            <SessionTimer unprotectedRoutes={nonProtectedRoutes} />
         ),
      [nonProtectedRoutes]
   );

   return (
      <>
         <Router>
            {sessionTimer}
            <Switch>
               <Route exact path={"/continue"} component={SelectTenantRoute} />
               <Route
                  exact
                  path={"/selecttenant"}
                  component={SelectTenantRoute}
               />
               <Route exact path={"/logout"} component={LogoutRoute} />
               <Route
                  exact
                  path={"/sendchangepassword"}
                  component={ChangePasswordRoute}
               />
               <Route
                  exact
                  path={"/changepassword"}
                  component={ChangePasswordRoute}
               />
               <Route path={"/Users"} component={UsersRoute} />
               <Route path={"/Order/OrderNotes"} component={OrderNotesRoute} />
               <Route path={"/Order"} component={OrderRoute} />
               <Route path={"/Equipment"} component={EquipmentRoute} />
               <Route path={"/Inspection"} component={InspectionRoute} />
               <Route path={"/Sites"} component={SitesRoute} />
               <Route path={"/Customers"} component={CustomersRoute} />
               <Route exact path={"/Login"} component={LoginRoute} />
               <Route component={DefaultRoute} />
            </Switch>
         </Router>
      </>
   );
};
export default App;
