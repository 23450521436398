import * as React from 'react'

export const withMaxWidth = (value: string, Component: () => JSX.Element): JSX.Element => {
  const root = document.getElementById('root')
  const MinWidth = (_props: any) => {
    React.useEffect(() => {
      if (root) {
        root.style.setProperty("max-width", value);
      }
      return () => {
        if (root) {
          root.style.removeProperty("max-width");
        }
      }
    })
    return (<></>)
  }

  return (
    <>
      <MinWidth />
      <Component />
    </>
  )
}
