import * as React from "react";
import { Auth0Config, BrandInfo } from "@msidata/zeact";
import local from "../config/local.config.json";
import dev from "../config/dev.config.json";
import qa from "../config/qa.config.json";
import mock from "../config/mock.config.json";

export interface PortalConfig {
   loaded: boolean;
   debug: boolean;
   defaultLanguage?: string;
   defaultLocale?: string;
   serviceProApiUrl: string;
   auth: Auth0Config;
   useAuth: boolean;
   brand?: BrandInfo;
   version?: string;
}

const getDefaultConfiguration = (): PortalConfig => ({
   loaded: false,
   debug: true,
   serviceProApiUrl: "",
   auth: {
      domain: "",
      clientId: "",
      audience: "",
      redirect_uri: "",
      allowed_connections: []
   },
   useAuth: true,
   defaultLanguage: "en-US",
   defaultLocale: "en-US",
   brand: { name: "ServiceProPortal" }
});

const configs: { [key: string]: PortalConfig } = {
   local,
   dev,
   qa,
   mock,
   test: { ...getDefaultConfiguration(), serviceProApiUrl: "test" }
};

export const useConfiguration = (): PortalConfig => {
   const [config, setConfig] = React.useState(getDefaultConfiguration());

   React.useEffect(() => {
      const specifiedConfig: string | undefined =
         process.env.NODE_ENV === "test"
            ? "test"
            : process.env.REACT_APP_CONFIG;
      if (specifiedConfig) {
         if (configs[specifiedConfig]) {
            setConfig({
               ...configs[specifiedConfig],
               version: `${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_BRANCH})`
            });
         } else {
            throw new Error(
               `Could not load the following config: ${process.env.REACT_APP_CONFIG}`
            );
         }
      } else {
         fetch(`/static/config.json`)
            .then(data => data.json())
            .then(configJson => {
               configJson["loaded"] = true;
               setConfig(configJson);
            })
            .catch(error => {
               throw new Error(`Could not load config: ${error}`);
            });
      }
   }, []);

   return config;
};
