import * as React from "react";
import {
   ZPopup,
   ZLoadingMask,
   ZWell,
   ErrorBoundary,
   ll,
   useLanguage
} from "@msidata/zeact";
import styled from "styled-components";
import { LanguagePicker } from "./LanguagePicker";
import { LocalePicker } from "./LocalePicker";
import { isAdmin } from "../hooks/useUser";
import { useAppContext } from "../data/AppContext";
import { useUserPreferences } from "../hooks/useUserPreferences";

interface UserPreferencesPopupProps {
   visible?: boolean;
   onClose?: (e?: any) => void;
   className?: string;
}

const UserPreferences = styled.div`
   display: grid;
   grid-gap: 10px;
   margin: 20px;
`;

const Warning = styled.div`
   color: red;
`;
export const UserPreferencesPopup = styled(
   (props: UserPreferencesPopupProps) => {
      const { pending: updatingUserPreferences } = useUserPreferences();
      const isServiceProUser = isAdmin();
      const { brand } = useAppContext().config;
      const languageId = useLanguage();
      return (
         <ErrorBoundary>
            <ZPopup
               title={"UserPreferences"}
               visible={true}
               onClose={() => {
                  props.onClose && props.onClose();
               }}
               className={props.className}
               width={600}
               height={620}
            >
               <ZWell header="LanguageAndLocale">
                  <UserPreferences>
                     <LanguagePicker disabled={isServiceProUser} />
                     <LocalePicker disabled={isServiceProUser} />
                  </UserPreferences>
               </ZWell>
               {isServiceProUser ? (
                  <Warning>
                     {ll({
                        key: "SetUserPreferencesIn",
                        variables: [
                           brand.name === "PULSEPortal"
                              ? ll({ key: "PulseService", languageId })
                              : ll({ key: "ServicePro", languageId })
                        ],
                        languageId
                     })}
                  </Warning>
               ) : null}
            </ZPopup>
            {updatingUserPreferences && (
               <ZLoadingMask containerSelector={`.z-popup .k-window`} />
            )}
         </ErrorBoundary>
      );
   }
)`
   & .z-popup-action-buttons {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
   }
   & .z-popup-create-button {
      margin-top: 15px;
   }
   & .z-popup-cancel-button {
      margin-top: 15px;
   }
   text-align: initial;
`;

export default UserPreferencesPopup;
