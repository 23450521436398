import {
   ReactQuery,
   useAlerts,
   displayAndLogErrorWithMessage
} from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";

const queryConfig = {
   suspense: false,
   cacheTime: 0
};

export const useUserCustomers = (
   userId: number | null,
   groupName?: string | null
): any => {
   const { apiClient } = useAppContext();
   const { issueAlert } = useAlerts();

   const fetchUserCustomers =
      userId && (groupName || groupName === undefined)
         ? () =>
              apiClient
                 .request({
                    url: `Administration/UserCustomer`,
                    params: {
                       userId,
                       groupName,
                       $top: 10000,
                       $select: "CustomerNumber,CustomerName"
                    }
                 })
                 .then(response => response.data.d.results)
                 .catch(error => {
                    displayAndLogErrorWithMessage(
                       error,
                       "UserCustomersCannotGet",
                       issueAlert
                    );
                    return Promise.resolve([]);
                 })
         : () => {
              return Promise.resolve(null);
           };

   const { data, isFetching } = ReactQuery.useQuery(
      ["UserCustomers", { userId, groupName }],
      fetchUserCustomers,
      queryConfig
   );

   return {
      userCustomers: data,
      pending: isFetching
   };
};
