import {
   ZDropDownButton,
   ZDropDownButtonItem,
   ZDropDownButtonProps,
   ll,
   llContext
} from "@msidata/zeact";
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import {
   defaultCulture,
   useCompanyInfoContext
} from "../data/CompanyInfoContext";
import { useUserPreferences } from "../hooks/useUserPreferences";

export const LanguagePicker = styled(
   (props: Partial<ZDropDownButtonProps> & { className?: string }) => {
      const { updateUserPreferencesMutation } = useUserPreferences();
      const { companyInfo } = useCompanyInfoContext();
      const { codes } = companyInfo;

      const { languageId, setLanguageId } = React.useContext(llContext);

      const languages = [
         { value: "en-US", text: "🇺🇸 English (US)" },
         { value: "en-GB", text: "🇬🇧 English (UK)" },
         { value: "en-AU", text: "🇦🇺 English (Australia)" },
         { value: "en-NZ", text: "🇳🇿 English (New Zealand)" },
         { value: "en-ZA", text: "🇿🇦 English (South Africa)" },
         { value: "es-MX", text: "🇲🇽 Spanish (México)" },
         { value: "fr-FR", text: "🇫🇷 French (France)" },
         { value: "ja-JP", text: "🇯🇵 Japanese (Japan)" }
      ];
      const excludedCodeNumbers = ["en-qt"];
      const languageCodes = codes?.Culture
         ? codes?.Culture.filter(
              c => !_.includes(excludedCodeNumbers, c.CodeNumber)
           ).map(culture => ({
              ...culture,
              text:
                 languages.find(l => l.value === culture.Value)?.text ||
                 culture.Value
           }))
         : [{ ...defaultCulture, text: defaultCulture.Value }];
      const [selection, setSelection] = React.useState(
         languageCodes.findIndex(
            l => l.Value.toLowerCase() === languageId.toLowerCase()
         )
      );
      const switchLanguage = (index: number) => {
         const value = languageCodes[index].Value;

         updateUserPreferencesMutation &&
            updateUserPreferencesMutation({
               Language: value
            }).then(() => {
               setSelection(index);
               if (setLanguageId) {
                  setLanguageId(value);
               }
            });
      };
      return (
         <div className={props.className}>
            <ZDropDownButton
               {...props}
               text={`${ll({
                  key: "Language",
                  languageId: languageCodes[selection]
                     ? languageCodes[selection].Value
                     : defaultCulture.Value
               })}: ${
                  languageCodes[selection]
                     ? languageCodes[selection].text
                     : defaultCulture.Value
               }`}
               onItemClick={(e: any) => switchLanguage(e.itemIndex)}
               look={"bare"}
               popupClass={props.className}
               localize={false}
            >
               {languageCodes.map((language, index) => (
                  <ZDropDownButtonItem
                     text={language.text || language.Value}
                     key={`language-${index}`}
                     selected={selection === index}
                  />
               ))}
            </ZDropDownButton>
         </div>
      );
   }
)`
   float: left;
`;
