import { CacheTimes, ReactQuery, useAlerts } from "@msidata/zeact";
import { User } from "@msidata/service-pro-api-schema";
import { useAppContext } from "../data/AppContext";

const queryConfig = {
   suspense: true,
   staleTime: CacheTimes.TwentyFourHours
};

export const useUser = (): User & { Type: string } => {
   const { apiClient } = useAppContext();
   const { issueAlert } = useAlerts();

   const fetchUser = () =>
      apiClient
         .request({ url: `Administration/User` })
         .then(response => response.data?.d?.results)
         .catch(error => {
            console.error(error);
            issueAlert({
               type: "error",
               header: "Error",
               message: "UserCannotGet",
               key: "UserCannotGet"
            });
         });
   const { data: user } = ReactQuery.useQuery("user", fetchUser, queryConfig);

   return user;
};

const useIsAdmin = () => {
   var user = useUser();

   return user?.Type === "Admin";
};
export const isAdmin = useIsAdmin;

const useIsGroupAdmin = () => {
   var user = useUser();

   return user?.Type === "GroupAdmin";
};
export const isGroupAdmin = useIsGroupAdmin;
