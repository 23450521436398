import {
   CacheTimes,
   ReactQuery,
   displayAndLogErrorWithMessage,
   useAlerts,
   useLanguage,
   useLocale,
   LLProvider,
   LocaleProvider
} from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";
import React from "react";
import { useCompanyInfoContext } from "../data/CompanyInfoContext";
import _ from "lodash";
const queryConfig = { staleTime: CacheTimes.TwentyFourHours, suspense: true };
export interface PortalUserPreferences {
   Locale?: string;
   Language?: string;
}

export const parsePortalUserPreferences = (
   preferences: {
      UserPreferenceNumber: keyof PortalUserPreferences;
      Value: any;
   }[]
) =>
   _.reduce(
      preferences,
      (prev, curr) => {
         prev[curr.UserPreferenceNumber] = curr.Value;
         return prev;
      },
      {} as PortalUserPreferences
   );

export const useUserPreferences = () => {
   const { apiClient } = useAppContext();
   const { issueAlert } = useAlerts();

   const fetchUserPreferences: () => Promise<
      PortalUserPreferences
   > = React.useCallback(
      () =>
         new Promise<PortalUserPreferences>(resolve => {
            const lsPrefs: PortalUserPreferences = JSON.parse(
               localStorage.getItem("userPreferences") || "null"
            );
            if (lsPrefs) {
               resolve(lsPrefs);
            } else {
               return apiClient
                  .get("UserPreferences/GetUserPreferences")
                  .then(response => {
                     if (response.status === 200) {
                        const prefs = parsePortalUserPreferences(
                           response?.data?.d?.results
                        );
                        localStorage.setItem(
                           "userPreferences",
                           JSON.stringify(prefs)
                        );
                        resolve(prefs);
                     } else {
                        resolve({});
                     }
                  })
                  .catch(error => {
                     displayAndLogErrorWithMessage(
                        error,
                        "GetUserPreferencesErrorMessage",
                        issueAlert
                     );
                     resolve({});
                  });
            }
         }),
      [apiClient, issueAlert]
   );

   const setUserPreferences = React.useCallback(
      (val: PortalUserPreferences) =>
         new Promise<PortalUserPreferences>(resolve =>
            apiClient
               .patch("UserPreferences/SetUserPreferences", val)
               .then(() => {
                  ReactQuery.queryCache.invalidateQueries("UserPreferences");
               })
               .catch(error => {
                  displayAndLogErrorWithMessage(
                     error,
                     "SetUserPreferencesErrorMessage",
                     issueAlert
                  );
                  resolve({});
               })
         ),
      [apiClient, issueAlert]
   );
   const [updateUserPreferencesMutation] = ReactQuery.useMutation(
      setUserPreferences,
      {
         onMutate: async () => {
            localStorage.removeItem("userPreferences");
         }
      }
   );
   const queryConfigMemo = React.useMemo(
      () => ({
         ...queryConfig,
         enabled: !!issueAlert && !!apiClient
      }),
      [issueAlert, apiClient]
   );

   const { data, error, refetch, isFetching } = ReactQuery.useQuery<
      PortalUserPreferences,
      "UserPreferences"
   >("UserPreferences", fetchUserPreferences, queryConfigMemo);

   return {
      userPreferences: data,
      updateUserPreferencesMutation,
      error,
      refresh: refetch,
      pending: isFetching
   };
};

const PrefsWrapper = (props: React.PropsWithChildren<any>) => {
   const { userPreferences } = useUserPreferences();
   const languageId = useLanguage();
   const localeId = useLocale();
   const { companyInfo } = useCompanyInfoContext();
   const { codes, settings } = companyInfo;
   let companyLocale = undefined,
      companyLanguage = undefined;
   if (settings.CultureId) {
      const culture = codes?.Culture
         ? codes.Culture.find(c => c.Id === settings.CultureId)
         : undefined;
      companyLanguage = culture?.Value;
      companyLocale = culture?.Value;
   }
   if (settings.DefaultLocale) {
      // 'DefaultLocale' in the API is actually Language
      const culture = codes?.Culture
         ? codes.Culture.find(c => c.CodeNumber === settings.DefaultLocale)
         : undefined;
      companyLanguage = culture?.Value;
   }
   return (
      <LocaleProvider
         value={{
            localeId: userPreferences?.Locale || companyLocale || localeId,
            setLocaleId: () => {}
         }}
      >
         <LLProvider
            value={{
               languageId:
                  userPreferences?.Language || companyLanguage || languageId,
               setLanguageId: () => {}
            }}
         >
            {props.children}
         </LLProvider>
      </LocaleProvider>
   );
};
export const withUserPreferences = (
   Component: () => JSX.Element
): JSX.Element => (
   <PrefsWrapper>
      <Component />
   </PrefsWrapper>
);
