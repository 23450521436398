import * as React from "react";
import {
   ZGrid,
   ZPopup,
   CustomGridColumnProps,
   FormattedValue,
   shortDateTimeFormat,
   useAlerts,
   useLanguage,
   ll
} from "@msidata/zeact";
import styled from "styled-components";
import { useCompletedInspectionResults } from "../hooks/useCompletedInspectionResults";
import { OrderLine } from "@msidata/service-pro-api-schema";
import { downloadAttachment } from "../util/utils";
import { useAppContext } from "../data/AppContext";

export interface InspectionResultReportProps {
   className?: string;
   orderId: number;
   onClose?: () => void;
}

export const InspectionResultReport = (props: InspectionResultReportProps) => {
   const { data, done } = useCompletedInspectionResults({
      orderId: props.orderId
   });
   const [ir, setIR] = React.useState(data);
   const userLanguage = useLanguage();
   const { issueAlert } = useAlerts();
   const { apiClient } = useAppContext();
   const Popup = styled(ZPopup)`
      .order-inspection-results-grid {
         height: 100%;
      }
   `;
   const Link = styled.a`
      color: ${props => props.theme["app-link-color"]} !important;
      :hover {
         color: ${props => props.theme["hover-color"]} !important;
         cursor: pointer;
      }
      :active {
         color: ${props => props.theme["active-color"]} !important;
      }
   `;

   React.useEffect(() => {
      setIR(data);
      return () => setIR(undefined);
   }, [data]);

   return (
      <Popup
         title={`InspectionResults`}
         className={props.className}
         visible={true}
         onClose={() => {
            setIR(undefined);
            props.onClose && props.onClose();
         }}
         resizable={false}
         height={575}
         width={1000}
      >
         <ZGrid
            data={ir}
            className={"order-inspection-results-grid"}
            pending={done}
            style={{
               width: "100%",
               height: 500
            }}
            columns={
               [
                  {
                     title: "InspectionName",
                     field: "Inspection.InspectionName",
                     filter: "text"
                  },
                  {
                     title: "VersionNumber",
                     field: "Inspection.VersionNumber",
                     filter: "numeric"
                  },
                  {
                     title: "CompletedBy",
                     field: "CompletedByUser.EmailAddress",
                     filter: "text",
                     cell: ({
                        dataItem,
                        className
                     }: {
                        dataItem: OrderLine;
                        className?: string;
                     }) => (
                        <td className={className}>
                           {dataItem.CompletedByUser === null ||
                           dataItem.CompletedByUser === undefined
                              ? "---"
                              : dataItem.CompletedByUser.EmailAddress}
                        </td>
                     )
                  },
                  {
                     title: "CompletedDate",
                     field: "CompletedDateTime",
                     filter: "date",
                     cell: ({
                        dataItem,
                        className
                     }: {
                        dataItem: OrderLine;
                        className?: string;
                     }) => (
                        <td className={className}>
                           {dataItem.CompletedDateTime === null ? (
                              "---"
                           ) : (
                              <FormattedValue
                                 value={dataItem.CompletedDateTime}
                                 format={shortDateTimeFormat}
                              ></FormattedValue>
                           )}
                        </td>
                     )
                  },
                  {
                     title: "Attachment",
                     sortable: false,
                     reorderable: false,
                     cell: ({
                        dataItem,
                        className
                     }: {
                        dataItem: OrderLine;
                        className?: string;
                     }) => (
                        <td className={className}>
                           {dataItem.InspectionResult === null ||
                           dataItem.InspectionResult === undefined ||
                           //@ts-ignore
                           dataItem.InspectionResult.AttachmentId === null ||
                           //@ts-ignore
                           dataItem.InspectionResult.AttachmentId ===
                              undefined ? (
                              <span>---</span>
                           ) : (
                              <Link
                                 onClick={() =>
                                    downloadAttachment({
                                       attachmentId:
                                          dataItem.InspectionResult
                                             ?.AttachmentId || 0,
                                       apiClient: apiClient,
                                       issueAlert: issueAlert
                                    })
                                 }
                              >
                                 {ll({
                                    key: "DownloadAttachment",
                                    languageId: userLanguage
                                 })}
                              </Link>
                           )}
                        </td>
                     )
                  }
               ] as CustomGridColumnProps[]
            }
            scrollable={"scrollable"}
            pageable={true}
            pageSize={10}
            sort={[{ dir: "desc", field: "CompletedDateTime" }]}
         />
      </Popup>
   );
};
