import enus from "./en-us.json";
import engb from "./en-gb.json";
import enza from "./en-za.json";
import ennz from "./en-nz.json";
import enau from "./en-au.json";
import esmx from "./es-mx.json";
import frfr from "./fr-fr.json";
import jajp from "./ja-jp.json";
import { loadLanguageJSON } from "@msidata/zeact";

loadLanguageJSON("en-US", enus);
loadLanguageJSON("en-GB", engb);
loadLanguageJSON("en-ZA", enza);
loadLanguageJSON("en-NZ", ennz);
loadLanguageJSON("en-AU", enau);
loadLanguageJSON("es-MX", esmx);
loadLanguageJSON("fr-FR", frfr);
loadLanguageJSON("ja-JP", jajp);
