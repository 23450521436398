import {
   ReactQuery,
   displayAndLogErrorWithMessage,
   useAlerts
} from "@msidata/zeact";
import { useAppContext } from "../data/AppContext";

const queryConfig = {
   suspense: false,
   cacheTime: 0
};

export const useGroupSites = (groupName: string | null): any => {
   const { apiClient } = useAppContext();
   const { issueAlert } = useAlerts();

   const fetchGroupSites = groupName
      ? () =>
           apiClient
              .request({
                 url: `Administration/GroupSite`,
                 params: {
                    groupName,
                    $top: 10000,
                    $select: "SiteNumber,SiteName"
                 }
              })
              .then(response => response.data.d.results)
              .catch(error => {
                 displayAndLogErrorWithMessage(
                    error,
                    "GroupSitesCannotGet",
                    issueAlert
                 );
                 return Promise.resolve([]);
              })
      : () => {
           return Promise.resolve(null);
        };

   const { data, isFetching } = ReactQuery.useQuery(
      ["GroupSites", { groupName }],
      fetchGroupSites,
      queryConfig
   );

   return {
      groupSites: data,
      pending: isFetching
   };
};
